<template>
    <div>
      <h1>Third Step</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores, officia,
        facere reiciendis autem veritatis iusto nobis in illo atque consectetur
        hic fugit sapiente deleniti odio nulla, quae iure incidunt animi.
      </p>
    </div>
  </template>
  