<template>
  <v-container class="pa-0" style="height: 100vh">
    <v-row class="h-100">
      <v-col class="facke-cols" cols="0" md="2" xl="2" sm="0"></v-col>
      <v-col class="contant-form pb-0" md="12" xl="8" sm="8">
        <v-row class="h-100 pa-0" style="background-color: #ebf9f9">
          <v-col sm="12" md="6" xl="6" class="left-side-img">
            <div class="top-logo">
              <img src="@/assets/soft-ware-img/Clip path group.png" alt="" />
            </div>
            <div class="main-image">
              <img
                class="img-fluid"
                src="@/assets/soft-ware-img/man-is-holding-phone-sign-that-says 1.png"
                alt=""
              />
            </div>
          </v-col>
          <v-col
            sm="12"
            md="6"
            xl="6"
            class="pa-8"
            style="background-color: #fff; border-radius: 50px 0 0 50px"
          >
            <div class="welcome-page">
              <div class="first-head" v-if="secoundStep">
                <h4>Welcome To Promxa</h4>
                <div class="request-have-account mt-12">
                  <h3>Request To Have Account</h3>
                  <!-- <p class="px-14">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi lobortis maximus
                  </p> -->
                </div>
              </div>
              <div class="secound-head" v-else>
                <h4>Complete Your Company Details</h4>
                <p class="text-center px-12">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  lobortis maximus
                </p>
              </div>
              <form
                action=""
                @submit.prevent="sendRequestToCereateAcc"
                class="mt-8 mb-3 d-block"
              >
                <div class="first-step" v-if="secoundStep">
                  <div class="your-name mb-5">
                    <label class="d-block" for="userName"> Name </label>
                    <input
                      ref="userNameInp"
                      type="text"
                      v-model="userData.name"
                      name=""
                      id="userName"
                    />
                  </div>
                  <div class="your-name mb-5">
                    <label class="d-block" for="userPhone">
                      Telephone Number
                    </label>
                    <input
                      ref="phoneNumber"
                      minlength="8"
                      maxlength="11"
                      type="text"
                      v-model="userData.phone"
                      name=""
                      id="userPhone"
                    />
                  </div>
                  <LoginBtn
                    @click="toSecoundStemp"
                    :textBtn="textBtn"
                    :type="type"
                  />
                  <div class="have-account my-3">
                    <p>
                      Already have an account?
                      <router-link to="/login"> Login </router-link>
                    </p>
                  </div>
                </div>
                <div class="secound-step" v-else>
                  <div class="your-name mb-2">
                    <label class="d-block mb-1" for="companyName">
                      Company Name
                    </label>
                    <input
                      ref="companyName"
                      type="text"
                      v-model="userData.company_name"
                      name=""
                      id="companyName"
                    />
                  </div>
                  <div class="your-name mb-2">
                    <label class="d-block mb-1" for="businessEmail">
                      Business Email
                    </label>
                    <input
                      type="email"
                      v-model="userData.bussiness_email"
                      name=""
                      ref="businessEmail"
                      id="businessEmail"
                    />
                  </div>
                  <div class="your-name mb-2">
                    <label class="d-block mb-1" for="fieldOfWork">
                      Field of work
                    </label>
                    <input
                      type="text"
                      v-model="userData.field_of_work"
                      name=""
                      id="fieldOfWork"
                      ref="fieldOfWork"
                    />
                  </div>
                  <div class="your-name mb-2">
                    <label class="d-block mb-1" for="companyPhoneNumber">
                      Company Telephone Number
                    </label>
                    <input
                      v-model="userData.company_phone"
                      type="text"
                      minlength="8"
                      maxlength="12"
                      name=""
                      id="companyPhoneNumber"
                      ref="companyPhoneNumber"
                    />
                  </div>
                  <div class="company-size">
                    <label for="">Company size</label>
                    <v-radio-group v-model="userData.company_size" inline>
                      <v-radio
                        label="0-50"
                        color="#1F666A"
                        value="0-50"
                      ></v-radio>
                      <v-radio
                        label="50-100"
                        color="#1F666A"
                        value="50-100"
                      ></v-radio>
                      <v-radio
                        label="100-200"
                        color="#1F666A"
                        value="100-200"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="accept-terms">
                    <v-checkbox color="#1F666A" v-model="userData.check_term">
                      <template v-slot:label>
                        <div>
                          By creating an account, I agree to our
                          <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }">
                              <a
                                href="#"
                                target="_blank"
                                v-bind="props"
                                @click.stop
                              >
                                Terms of use and Privacy Policy
                              </a>
                            </template>
                            Opens in new window
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                  <LoginBtn :textBtn="textBtn_2" :type="type_2" />
                </div>
              </form>
              <div class="steps">
                <span
                  @click="firstStepFuc"
                  :class="secoundStep ? 'active' : ''"
                ></span>
                <span
                  @click="secoundStepFuc"
                  :class="secoundStep ? '' : 'active'"
                ></span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="facke-cols" cols="0" md="2" xl="2" sm="0"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginBtn from "@/components/buttons/LoginBtn.vue";
import { toast } from "vue3-toastify";
import apiClient from "@/core/buildRequest"; // Use apiClient instead of axios

export default {
  components: {
    LoginBtn,
  },
  name: "SignUp",
  data() {
    return {
      textBtn: "Next",
      type: "button",
      type_2: "submit",
      textBtn_2: "Send",
      secoundStep: true,
      activeStep: "active",
      userData: {
        name: null,
        phone: null,
        company_name: null,
        bussiness_email: null,
        field_of_work: null,
        company_phone: null,
        company_size: null,
        check_term: false,
      },
    };
  },
  methods: {
    toSecoundStemp() {
      // Reset error states before validation
      this.$refs.userNameInp.classList.remove("error");
      this.$refs.userNameInp.placeholder = "";
      this.$refs.phoneNumber.classList.remove("error");
      this.$refs.phoneNumber.placeholder = "";
      if (!this.userData.name && !this.userData.phone) {
        this.$refs.userNameInp.classList.add("error");
        this.$refs.userNameInp.placeholder = "Please Fill This Input";
        this.$refs.phoneNumber.classList.add("error");
        this.$refs.phoneNumber.placeholder = "Please Fill This Input";

        this.secoundStep = true;
      } else if (!this.userData.name) {
        this.$refs.userNameInp.classList.add("error");
        this.$refs.userNameInp.placeholder = "Name is required";
        this.secoundStep = true;
      } else if (!this.userData.phone) {
        this.$refs.phoneNumber.classList.add("error");
        this.$refs.phoneNumber.placeholder = "Telephone number is required";
        this.secoundStep = true;
      } else if (this.userData.phone.length < 8) {
        this.$refs.phoneNumber.classList.add("error");
        this.$refs.phoneNumber.placeholder =
          "Phone number must be at least 8 characters";
        this.$refs.phoneNumber.value = "";
        this.secoundStep = true;
      } else {
        // All validations passed
        this.secoundStep = false;
      }
    },
    firstStepFuc() {
      this.secoundStep = true;
    },
    secoundStepFuc() {
      this.$refs.userNameInp.classList.remove("error");
      this.$refs.userNameInp.placeholder = "";
      this.$refs.phoneNumber.classList.remove("error");
      this.$refs.phoneNumber.placeholder = "";
      if (!this.userData.name && !this.userData.phone) {
        this.$refs.userNameInp.classList.add("error");
        this.$refs.userNameInp.placeholder = "Please Fill This Input";
        this.$refs.phoneNumber.classList.add("error");
        this.$refs.phoneNumber.placeholder = "Please Fill This Input";

        this.secoundStep = true;
      } else if (!this.userData.name) {
        this.$refs.userNameInp.classList.add("error");
        this.$refs.userNameInp.placeholder = "Name is required";
        this.secoundStep = true;
      } else if (!this.userData.phone) {
        this.$refs.phoneNumber.classList.add("error");
        this.$refs.phoneNumber.placeholder = "Telephone number is required";
        this.secoundStep = true;
      } else if (this.userData.phone.length < 8) {
        this.$refs.phoneNumber.classList.add("error");
        this.$refs.phoneNumber.placeholder =
          "Phone number must be at least 8 characters";
        this.$refs.phoneNumber.value = "";
        this.secoundStep = true;
      } else {
        // All validations passed
        this.secoundStep = false;
      }
    },
    sendRequestToCereateAcc() {
      let isValid = true;
      if (!this.userData.company_name) {
        this.$refs.companyName.classList.add("error");
        this.$refs.companyName.placeholder = "Please fill this input";
        isValid = false;
      } else {
        this.$refs.companyName.classList.remove("error");
        this.$refs.companyName.placeholder = "";
      }
      if (!this.userData.bussiness_email) {
        this.$refs.businessEmail.classList.add("error");
        this.$refs.businessEmail.placeholder = "Please fill this input";
        isValid = false;
      } else {
        this.$refs.businessEmail.classList.remove("error");
        this.$refs.businessEmail.placeholder = "";
      }
      if (!this.userData.field_of_work) {
        this.$refs.fieldOfWork.classList.add("error");
        this.$refs.fieldOfWork.placeholder = "Please fill this input";
        isValid = false;
      } else {
        this.$refs.fieldOfWork.classList.remove("error");
        this.$refs.fieldOfWork.placeholder = "";
      }
      if (!this.userData.company_phone) {
        this.$refs.companyPhoneNumber.classList.add("error");
        this.$refs.companyPhoneNumber.placeholder = "Please fill this input";
        isValid = false;
      } else {
        this.$refs.companyPhoneNumber.classList.remove("error");
        this.$refs.companyPhoneNumber.placeholder = "";
      }
      if (!this.userData.check_term) {
        toast("You must agree to the terms and conditions.", {
          type: "error",
        });
        isValid = false;
      }
      if (!isValid) {
        return;
      }
      apiClient
        .post("/guest-registration", this.userData)
        .then((response) => {
          toast(`${response.data.msg}`, {
            type: "success",
          });
          setTimeout(() => {
            this.$router.push({ name: "LoginPage" });
          }, 2000);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            // Specific server error handling
            if (
              error.response.data.msg ===
              "يجب أن يكون طول النص company phone على الأقل 6 ارقام"
            ) {
              toast(`${error.response.data.msg}`, {
                type: "error",
              });
            } else {
              toast(`${error.response.data.msg}`, {
                type: "error",
              });
            }
          } else {
            // General error handling for unexpected errors
            toast("An unexpected error occurred. Please try again later.", {
              type: "error",
            });
          }
          if (error.isAxiosError) {
            this.$router.push({
              name: "NotFound",
              params: { catchAll: "path-not-found" },
            });
          }
        });
    },
  },
};
</script>

<style scoped>
@media (max-width: 1220px) {
  .facke-cols {
    display: none;
  }
  .contant-form {
    margin: 0 auto;
  }
}
.v-selection-control {
  align-items: start !important;
}
.welcome-page {
  border: 1px solid #66666659;
  padding: 15px 30px;
  border-radius: 20px;
  width: 90%;
  margin: 0 auto;
  height: 100%;
  position: relative;
}
.welcome-page .secound-head p {
  color: #666666;
}
.welcome-page .steps {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}
.welcome-page .steps span {
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 1px solid #66666659;
  cursor: pointer;
}
.welcome-page .steps span.active {
  background-color: #1f666a;
}

.welcome-page h4 {
  color: #000;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}
.welcome-page .request-have-account h3 {
  font-size: 24px;
  font-weight: 700;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.welcome-page .request-have-account p {
  color: #666666;
  font-size: 16px;
  text-align: center;
}
.welcome-page form .secound-step .your-name label,
.welcome-page form .first-step .your-name label {
  color: #666666;
  font-size: 16px;
}
.welcome-page form .secound-step .your-name input,
.welcome-page form .first-step .your-name input {
  border: 1px solid #66666659 !important;
  padding: 13px 10px;
  width: 100%;
  border-radius: 15px;
  outline: none;
}
.welcome-page form .secound-step .your-name input:hover {
  outline: none !important;
  box-shadow: none;
  border: 1px solid #66666659;
}
.contant-form .left-side-img {
  background-color: #ebf9f9;
  border-radius: 40px 0 0 40px;
}
.main-image img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.first-step .have-account p {
  font-size: 16px;
  color: #49475a;
  font-weight: 500;
  text-align: center;
}
.first-step .have-account p a {
  color: #1f666a;
}
.error {
  border: 1px solid #e84e3c !important;
  outline: 1px solid #e84e3c !important;
}
</style>
