<template>
  <div class="locations-card ad-set-details-card mb-6">
    <div
      class="locations-parent d-flex mb-4 align-center ga-12 justify-space-between"
    >
      <div class="location-header">
        <h6 class="page-header-title mb-1">Locations</h6>
        <p>
          As of July 25th, 2024, location targeting will include people who we
          believe are living, working, or have recently been in the area. This
          change only affects new ad sets. <a href="">Learn More.</a>
        </p>
      </div>
      <button @click="changeDetactedLocationType" class="locations-button">
        <span v-if="buttonContain">Target Locations on Map</span>
        <span v-else>Target Predefined Locations</span>
      </button>
    </div>
    <v-card
      max-width="100%"
      class="pa-5 mb-5 rounded-lg card-content"
      style="overflow: visible"
      elevation="1"
    >
      <div class="card-header mb-4 d-flex align-center justify-space-between">
        <p>Your audience is located in:</p>
        <div class="d-flex align-center ga-5">
          <button type="button" @click="emptySelectedCountry">
            <span>
              <svg
                width="15"
                height="15"
                viewBox="0 0 16 16"
                fill="#33bbff"
                xmlns="http://www.w3.org/2000/svg"
                class="sds-icon sds-icon-refresh"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.72491 3.77886C6.45718 3.77886 5.24137 4.28247 4.34494 5.17889C3.44852 6.07531 2.94491 7.29113 2.94491 8.55886C2.94491 9.82509 3.44733 11.0395 4.34175 11.9356C5.23873 12.807 6.44377 13.2886 7.69436 13.2755C8.94646 13.2623 10.1425 12.7543 11.0212 11.8622L11.0249 11.8585L11.0286 11.8549C11.9184 10.9793 12.4321 9.79158 12.4607 8.54357L13.7937 8.57415C13.7572 10.1671 13.1021 11.6833 11.9674 12.8017C10.8418 13.9423 9.31094 14.5919 7.70838 14.6087C6.10408 14.6256 4.55828 14.007 3.40856 12.888L3.40852 12.888L3.40213 12.8816C2.25566 11.7352 1.61158 10.1802 1.61158 8.55886C1.61158 6.9375 2.25566 5.38255 3.40213 4.23608C4.54861 3.08961 6.10356 2.44553 7.72491 2.44553C9.26819 2.44553 10.7513 3.02907 11.8793 4.07396L11.9832 3.06022L13.3096 3.19626L12.99 6.31284L9.87781 6.61549L9.74875 5.28842L11.0844 5.15853C10.19 4.27484 8.98302 3.77886 7.72491 3.77886ZM11.8415 4.44229L11.5763 4.70748L11.8416 4.44136L11.8415 4.44229Z"
                ></path>
              </svg>
            </span>
            <span class="btn-text">Rest</span>
          </button>
          <button type="button" @click="dialog = true">
            <span>
              <svg
                width="15"
                height="15"
                viewBox="0 0 16 16"
                fill="#33bbff"
                xmlns="http://www.w3.org/2000/svg"
                class="sds-icon sds-icon-upload"
              >
                <path
                  d="M2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667L2 9.33333L3.33333 9.33333L3.33333 12.6667L12.6667 12.6667L12.6667 9.33333L14 9.33333L14 12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14L3.33333 14C2.97971 14 2.64057 13.8595 2.39052 13.6095Z"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.37337 4.27611L5.93141 5.71807L4.9886 4.77526L8.04001 1.72385L11.0914 4.77526L10.1486 5.71807L8.7067 4.27617L8.7067 11.3333L7.37337 11.3333L7.37337 4.27611Z"
                ></path>
              </svg>
            </span>
            <span class="btn-text">Upload Locations in Bulk </span>
          </button>
          <v-dialog v-model="dialog" width="600">
            <v-card max-width="600" class="mb-5 upload-locations rounded-lg">
              <div class="card-header" style="padding: 24px 24px 0 24px">
                <h4>Upload Locations in Bulk</h4>
              </div>
              <div class="card-body px-6 my-4">
                <div class="mb-4">
                  <label for="" class="mb-1"
                    >Select country or upload countries in bulk</label
                  >
                  <treeselect
                    v-model="selectedCountries"
                    :multiple="true"
                    :options="countryList"
                    :value-format="'object'"
                  ></treeselect>
                </div>
                <div class="location-type">
                  <p class="mb-1">Location Type</p>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="pa-0"
                      ><label
                        for=""
                        class="fs-12 d-block"
                        style="color: #000000d9"
                        >Please only upload locations within your selected
                        country.
                      </label></v-col
                    >
                    <v-col cols="3" class="ma-0 py-1 px-0">
                      <v-select
                        class="v-select-style"
                        variant="solo"
                        v-model="publisherStoriesSelectedCategoriesTypes"
                        :items="publisherStoriesCategoriesTypes"
                      ></v-select>
                    </v-col>
                    <v-col cols="9" class="ma-0 py-1 px-0">
                      <v-select
                        class="v-select-style"
                        variant="solo"
                        v-model="regionsModel"
                        :items="regions"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <div class="text-all-location mt-6">
                    <div
                      class="location-text-header d-flex align-center justify-space-between"
                    >
                      <span class="copy-text-title" style=""
                        >Copy and paste a list of locations
                      </span>
                      <p class="ma-0 d-flex align-center ga-2 download-btn">
                        <span>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="#33bbff"
                            xmlns="http://www.w3.org/2000/svg"
                            class="sds-icon sds-icon-download styles-1qa37xd"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.1486 7.33912L8.70666 8.78108L8.70666 1.72385H7.37333L7.37333 8.78102L5.93143 7.33912L4.98862 8.28193L8.04003 11.3333L11.0914 8.28193L10.1486 7.33912ZM2 12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H12.6667C13.0203 14 13.3594 13.8595 13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6667V9.33334H12.6667V12.6667H3.33333L3.33333 9.33334H2V12.6667Z"
                            ></path>
                          </svg>
                        </span>
                        <span class="font-weight-regular position-relative"
                          >Download available locations</span
                        >
                      </p>
                    </div>
                    <div class="location-text-body">
                      <textarea
                        v-model="locationText"
                        placeholder="Regions may be entered as either comma-, semicolon- or newline-separated values.

Examples below:

Alabama, Mississippi

Nevada; Washington

California
New York"
                        name=""
                        id=""
                        @input="handleInputChange"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <template v-slot:actions>
                <button @click="dialog = false" class="cancel-btn">
                  <span>Cancel</span>
                </button>
                <button
                  @click="dialog = false"
                  :disabled="isUploadDisabled"
                  class="upload-btn disapled"
                >
                  Upload
                </button>
              </template>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="card-body">
        <div class="country-select">
          <p
            class="d-flex align-center justify-space-between"
            v-for="country in selectedCountries"
            :key="country.id"
          >
            <span class="country d-flex">{{ country.label }}</span>
            <span @click="removeElementFromArray(country)" class="cross">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="#33bbff"
                xmlns="http://www.w3.org/2000/svg"
                class="sds-icon sds-icon-cross"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                ></path>
              </svg>
            </span>
          </p>
        </div>
        <treeselect
          v-model="selectedCountries"
          :multiple="true"
          :options="countryList"
          :value-format="'object'"
        >
        </treeselect>
      </div>
    </v-card>
  </div>
</template>

<script>
import "@/assets/snapchatStyle/style.css";
import Treeselect from "vue3-treeselect";
// import the styles
import "vue3-treeselect/dist/vue3-treeselect.css";
export default {
  components: {
    Treeselect,
  },
  data() {
    return {
      buttonContain: true,
      selectedCountries: [],
      dialog: false,
      countryList: [
        {
          id: 1,
          label: "South America",
          children: [
            { id: 2, label: "California" },
            { id: 3, label: "Colorado" },
            { id: 4, label: "Florida" },
            { id: 5, label: "Georgia" },
            { id: 6, label: "Texas" },
            { id: 7, label: "Wyoming" },
          ],
        },
        {
          id: 8,
          label: "Europe",
          children: [
            { id: 9, label: "France" },
            { id: 10, label: "Germany" },
            { id: 11, label: "Spain" },
            { id: 12, label: "Italy" },
            { id: 13, label: "Portugal" },
          ],
        },
        {
          id: 14,
          label: "Asia",
          children: [
            { id: 15, label: "China" },
            { id: 16, label: "Japan" },
            { id: 17, label: "India" },
            { id: 18, label: "South Korea" },
            { id: 19, label: "Thailand" },
          ],
        },
      ],
      publisherStoriesSelectedCategoriesTypes: "include",
      publisherStoriesCategoriesTypes: ["include", "exclude"],
      regions: ["regions"],
      regionsModel: "regions",
      locationText: "",
    };
  },
  methods: {
    changeDetactedLocationType() {
      if (this.buttonContain) {
        this.buttonContain = false;
      } else {
        this.buttonContain = true;
      }
    },
    removeElementFromArray(country) {
      this.selectedCountries = this.selectedCountries.filter(
        (item) => item.id !== country.id
      );
    },
    emptySelectedCountry() {
      this.selectedCountries = [];
    },
    handleButtonClick(option) {
      console.log("Button clicked for:", option);
      // Add your logic here for including or excluding
    },
  },
  watch: {
    isUploadDisabled() {
      // The upload button is disabled if locationText is empty
      return this.locationText.trim() === "";
    },
  },
};
</script>
