import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import EN from "@/locale/en.json";
import AR from "@/locale/ar.json";
// import "@/assets/css/style.css";

// Vuetify
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
const vuetify = createVuetify({
  components,
  directives,
});

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

// Add the desired icons to the library
library.add(faUserSecret);

import { faCoffee } from "@fortawesome/free-solid-svg-icons";
library.add(faCoffee);

// Import the brand icon (square-facebook) from the free brands pack
import { faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
library.add(faSquareFacebook);

import "vue3-toastify/dist/index.css";

const i18n = createI18n({
  locale: localStorage.getItem("lang")?.toUpperCase() || "EN",
  fallbackLocale: "EN",
  messages: {
    EN,
    AR,
  },
});

createApp(App)
  .use(vuetify)
  .use(router)
  .use(createPinia())
  .use(i18n)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
