import axios from "axios";
import config from "@/../config";
import { toast } from "vue3-toastify"; // '@/config' resolves to 'src/config.js'

const apiClient = axios.create({
  baseURL: config.api_base_url_stg, // Base URL of your API
  headers: {
    "Content-Type": "application/json",
  },
  // timeout: 10000, // Timeout for requests in ms (optional)
});

// Add a request interceptor if needed
apiClient.interceptors.request.use(
  (config) => {
    // Example: Add token if available
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor for global error handling
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle global errors here
    if (error.response && error.response.data) {
      toast(`${error.response.data.msg}`, {
        type: "error",
      });
    } else {
      toast("An unexpected error occurred. Please try again later.", {
        type: "error",
      });
    }
    return Promise.reject(error);
  }
);

export default apiClient;
