<template>
    <div class="parent w-100 position-relative">
      <header class="">
        <div>
          <span> Snapchat Ads </span>
        </div>
        <div>
          <img src="@/assets/snapchatImage/download.svg" alt="" />
        </div>
        <div style="width: 5%">
          <button type="button">logout</button>
        </div>
      </header>
      <v-container
        fluid
        style="min-height: calc(100vh - 55px); display: grid; padding: 0"
      >
        <v-row class="h-100 ma-0" style="background-color: #f9f9fa">
          <v-col cols="3" class="py-0 px-0" style="position: relative">
            <SideNav firstTile="Sales" />
          </v-col>
          <v-col cols="7" class="main-contain pa-8">
            <div class="all-contain-form">
              <BuildCampaign />
            </div>
            <div class="footer d-flex align-center justify-center">
              <div class="d-flex align-center justify-end footer-contain">
                <!-- <v-btn
                  type="button"
                  color="#444546"
                  rounded
                  class="footer-back-btn"
                  variant="outlined"
                  >Back</v-btn
                > -->
                <div class="d-flex align-center">
                  <p>
                    <span>
                      <svg
                        viewBox="0 0 16 16"
                        fill="#39CA8E"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        class="sds-icon sds-icon-check-circle styles-15ro776"
                      >
                        <path
                          d="M14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992ZM11.1422 5.2486L6.90277 9.488L4.88826 7.47349L3.94545 8.4163L6.90277 11.3736L12.085 6.19141L11.1422 5.2486Z"
                        ></path>
                      </svg>
                    </span>
                    Draft Saved
                  </p>
                  <v-btn
                    type="button"
                    color="#444546"
                    rounded
                    @click="nextStep"
                    class="footer-next-btn"
                    variant="outlined"
                    >Next</v-btn
                  >
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  import BuildCampaign from "@/components/snapchat/CampaignSetup.vue";
  import SideNav from "@/components/snapchat/SideNav.vue";
  import "@/assets/snapchatStyle/style.css";
  export default {
    components: { BuildCampaign, SideNav },
  
    methods: {
      nextStep() {
        this.$router.push({ name: "salesSecoundStep" });
      },
    },
  };
  </script>
  