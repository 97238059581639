<template>
  <div class="container">
    <table class="table-drag-select" id="dayTimeTable">
      <tbody>
        <tr class="table-drag-first-row">
          <td class="table-drag-first-column">
            <button @click="clearSelections" type="button" class="clear-btn">
              <span>Clear</span>
            </button>
          </td>
          <td v-for="hour in hours" :key="hour" class="cell-disabled hour-cell">
            <span>{{ hour }}</span>
          </td>
        </tr>
        <tr v-for="(day, dayIndex) in days" :key="dayIndex">
          <td class="table-drag-first-column cell-disabled day-cell">
            <span>{{ day }}</span>
          </td>
          <td
            v-for="hour in hours"
            :key="hour"
            @mousedown="startDrag(day, hour)"
            @mouseenter="isDragging ? dragOver(day, hour) : null"
            @mouseup="endDrag"
            :class="{
              'cell-selected': isSelected(day, hour),
              'cell-enabled': !isSelected(day, hour),
            }"
          >
            <span>&nbsp;</span>
          </td>
        </tr>
      </tbody>
    </table>
    <v-container>
      <span class="text-muted small-text ml-16">
        Your ad is scheduled to run:
      </span>
      <pre class="text-muted small-text ml-16">{{ formatSelection() }}</pre>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      hours: [
        "12 am",
        "1 am",
        "2 am",
        "3 am",
        "4 am",
        "5 am",
        "6 am",
        "7 am",
        "8 am",
        "9 am",
        "10 am",
        "11 am",
        "12 pm",
        "1 pm",
        "2 pm",
        "3 pm",
        "4 pm",
        "5 pm",
        "6 pm",
        "7 pm",
        "8 pm",
        "9 pm",
        "10 pm",
        "11 pm",
      ],
      selectedHours: {},
      isDragging: false,
      startDay: "",
      startHour: "",
    };
  },
  methods: {
    startDrag(day, hour) {
      this.isDragging = true;
      this.toggleSelection(day, hour);
      this.startDay = day;
      this.startHour = hour;
    },
    dragOver(day, hour) {
      if (this.isDragging) {
        this.toggleSelection(day, hour);
      }
    },
    endDrag() {
      this.isDragging = false;
    },
    toggleSelection(day, hour) {
      if (!this.selectedHours[day]) {
        this.selectedHours[day] = [];
      }
      const index = this.selectedHours[day].indexOf(hour);
      if (index > -1) {
        this.selectedHours[day].splice(index, 1);
        if (this.selectedHours[day].length === 0) {
          delete this.selectedHours[day];
        }
      } else {
        this.selectedHours[day].push(hour);
      }
    },
    isSelected(day, hour) {
      return this.selectedHours[day] && this.selectedHours[day].includes(hour);
    },
    clearSelections() {
      this.selectedHours = {};
    },
    formatSelection() {
      const output = [];
      for (const day in this.selectedHours) {
        const times = this.selectedHours[day].sort(
          (a, b) => this.hours.indexOf(a) - this.hours.indexOf(b)
        );
        const ranges = [];
        let rangeStart = times[0];
        let rangeEnd = times[0];

        for (let i = 1; i < times.length; i++) {
          if (
            this.hours.indexOf(times[i]) ===
            this.hours.indexOf(rangeEnd) + 1
          ) {
            rangeEnd = times[i];
          } else {
            ranges.push(
              rangeStart === rangeEnd
                ? rangeStart
                : `${rangeStart} until ${rangeEnd}`
            );
            rangeStart = times[i];
            rangeEnd = times[i];
          }
        }
        ranges.push(
          rangeStart === rangeEnd
            ? rangeStart
            : `${rangeStart} until ${rangeEnd}`
        );

        output.push(`${day}, ${ranges.join(", and ")}`);
      }
      return output.join("\n");
    },
  },
};
</script>

<style>
.container {
  max-width: 100%; /* Ensure the container does not exceed its width */
  overflow-x: auto; /* Allow horizontal scrolling if necessary */
}

.table-drag-select {
  border-collapse: collapse;
  width: 100%; /* Set width to 100% of the parent */
}

.cell-disabled {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  text-align: center;
  padding: 5px;
  width: 30px;
}

.cell-enabled {
  cursor: pointer;
  border: 1px solid #ccc;
  text-align: center;
  padding: 5px;
  width: 30px;
}

.cell-selected {
  background-color: #f7d901;
  color: white;
  border: 2px solid #ffffff;
}

.day-cell,
.hour-cell {
  background-color: transparent;
  border: unset !important;
  font-size: 12px;
}

.clear-btn {
  cursor: pointer;
  font-size: 13px;
  height: 24px;
  line-height: 24px;
  margin-right: 8px;
  min-height: unset;
  padding: 0 16px;
  background-color: #fff;
  box-shadow: 0 0 0 1px #d1d3d5;
  color: #444546;
}

.clear-btn:hover {
  box-shadow: 0 0 0 2px #f7d901;
  line-height: 24px;
  margin-right: 8px;
  min-height: unset;
  padding: 0 16px;
  background-color: #fff;
  color: #444546;
}
</style>
