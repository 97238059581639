<template>
  <v-dialog
    v-model="create_website_events_audiance_dialog"
    max-width="55%"
    persistent
  >
    <v-card class="pa-5 radius-10">
      <div>
        <span>Create New Customer List Audience</span>
      </div>

      <v-spacer></v-spacer>
      <div class="body-header">
        <div class="have-you">Have you created your Snap Pixel?</div>
        <div class="desc">
          We have not found a Snap Pixel associated with this ad account. Make
          sure to create your Snap Pixel in the
          <a href="" class="blue-link">Pixel Page</a> and add it to your
          website. <a href="" class="blue-link"> Learn More </a>
        </div>
      </div>
      <div>
        <span>Create New Customer List Audience</span>
      </div>

      <div class="body mt-3">
        <div class="filed mt-3">
          <span class="title">Audiance Name</span>
          <input
            type="text"
            v-model="audiance_name"
            placeholder="Enter Your Audiance Name"
          />
          <div class="warning">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#444546"
              xmlns="http://www.w3.org/2000/svg"
              class="warning-svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
              ></path>
            </svg>
            <p class="small-text muted-text">
              The audience must be given a name
            </p>
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Choose a Pixel</span>
          <v-select
            v-model="pixels"
            class="v-select-style"
            :disabled="pixelsItems.length === 0"
            :items="pixelsItems"
          ></v-select>
          <a href="" class="gray-link muted-text"> Manage Pixels </a>
        </div>
        <div class="filed mt-3">
          <span class="title"
            >Include people who have done ANY of the following actions</span
          >
          <v-autocomplete
            :items="userActionsItems"
            v-model="userActions"
            class="v-autocomplete-style"
            placeholder="Search For Interactions"
            variant="solo"
            item-props
            rounded
            multiple
          ></v-autocomplete>
        </div>
      </div>
      <div class="mt-5 d-flex justify-end align-center ga-3">
        <a
          @click="create_website_events_audiance_dialog = false"
          class="cancel-btn"
          >Cancel</a
        >

        <v-btn class="upload-button" :readonly="!upload_ready"> Upload </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateNewCustomerListAudience",
  data() {
    return {
      create_website_events_audiance_dialog: true,
      audiance_name: "",
      method_type: "upload_file",
      past_and_copy: "",
      data_type: "",
      description: "",
      upload_ready: false,
      pixelsItems: [],
      userActionsItems: ["clicked", "liked", "commented", "shared"],
      userActions: [],
    };
  },
  methods: {
    openDialog() {
      this.create_website_events_audiance_dialog = true;
    },
    closeDialog() {
      this.create_website_events_audiance_dialog = false;
    },
    handlePaste(event) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      this.past_and_copy = pastedText;
    },
    clearOnBackspace(event) {
      if (event.key === "Backspace") {
        this.past_and_copy = "";
      }
    },
  },
};
</script>
<style scoped>
.body-header {
  background: #fff;
  border: 1px solid #ffb18a;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  margin: 16px 0px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 8px 0px;
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 0;
  margin-top: 20px;
}
.body-header .have-you {
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  padding: 16px 24px;
  font-weight: 700;
}
.body-header .desc {
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-height: 100000px;
  opacity: 1;
  will-change: max-height, opacity;
  overflow: auto;
  padding: 16px 24px;
  transition: opacity 100ms cubic-bezier(0.55, 0.055, 0.675, 0.19),
    max-height 250ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
  font-size: 12px;
}
.warning {
  display: flex;
  gap: 5px;
}
.warning-svg {
  display: flex;
  color: rgb(8, 148, 250);
  fill: rgb(8, 148, 250);
  flex-shrink: 0;
}
.blue-link {
  color: #3bf;
  text-decoration: none;
}
.blue-link:hover {
  color: #0894fa;
}

.gray-link {
  font-size: 12px;
  color: rgb(53, 56, 58);
}
.gray-link:hover {
  color: rgb(53, 56, 58);
}
input {
  background-color: #f9f9fa;
}

.filed .title {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.yellow-link {
  background-color: #f7d901;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.815);
}
.yellow-link:hover {
  background-color: unset;
  color: rgba(0, 0, 0, 0.815);
}

.cancel-btn {
  background: unset;
  border: unset;
  color: black;
  box-shadow: unset;
  background-color: unset;
  position: relative;
  padding-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
}

.cancel-btn .v-btn__content {
  position: relative;
}

.cancel-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: blue;
  transition: width 0.3s ease;
}

.cancel-btn:hover::after {
  width: 100%;
}

.cancel-btn:hover {
  color: blue;
}
.upload-button:hover {
  box-shadow: unset;
}
.upload-button {
  align-items: center;
  border: none;
  border-radius: 100px;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  justify-content: center;
  line-height: 1;
  min-height: 32px;
  outline: 1px;
  padding: 8px 20px;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  background: white;
  box-shadow: 0 0 0 1px #d1d3d5;
  color: #545557;
}
.upload-button.v-btn--readonly,
.v-input--disabled {
  background: #e1e3e5
    repeating-linear-gradient(
      45deg,
      #d1d3d5,
      #d1d3d5 1px,
      #e1e3e5 0,
      #e1e3e5 5px
    );
}
.upload-button:hover .v-btn__overlay {
  opacity: 0px;
}
.v-autocomplete__style:hover {
  border: 2px solid #f7d901 !important;
}
</style>
