<template>
  <div class="create-campain-modules">
    <NavBar />
    <v-container
      fluid="false"
      style="margin-top: 3%; padding: 1% 0 0 0; height: calc(100vh - 90.66px)"
    >
      <v-row style="height: 100%; margin: 0; padding: 0">
        <!-- <v-col cols="1"></v-col> -->
        <v-col cols="2" style="height: 100%" class="pa-0">
          <div class="side-nav-parent-soft-ware">
            <div ref="sideNavParent" class="side-nav-soft-ware px-0 py-3">
              <button
                class=" mx-4"
                style=" padding: 0"
                @click="toggleSideNav"
              >
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center ga-5 w-100 icon-text-contant">
                    <img
                      src="@/assets/soft-ware-img/online-advertising_12516043 1.svg"
                      alt=""
                    />
                    <span>Ads</span>
                  </div>
                  <p @click="dropDown('expand2')">
                    <span v-if="!expand2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="15"
                        height="15"
                        fill="rgba(31, 102, 106, 1)"
                      >
                        <path
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                        />
                      </svg>
                    </span>
                    <span v-else>
                      <svg
                        width="15"
                        height="15"
                        fill="rgba(31, 102, 106, 1)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                        />
                      </svg>
                    </span>
                  </p>
                </div>
              </button>
              <v-expand-transition>
                <v-card
                  v-show="expand2"
                  class="pl-10 pr-4 bg-transparent"
                  height=""
                  width="100%"
                  elevation="0"
                >
                  <router-link to="/create-new-camp">
                    <span>
                      <img
                        src="@/assets/soft-ware-img/Layer_x0020_1.svg"
                        alt=""
                      />
                    </span>
                    <span>Create ADs / Campaign</span>
                  </router-link>
                  <router-link to="/">
                    <span>
                      <img src="@/assets/soft-ware-img/chartsIcon.svg" alt="" />
                    </span>
                    <span>ADs Performance</span>
                  </router-link>
                  <router-link to="/">
                    <span>
                      <img
                        src="@/assets/soft-ware-img/Group_Keyworsd.svg"
                        alt=""
                      />
                    </span>
                    <span>Keywords Planner</span>
                  </router-link>
                </v-card>
              </v-expand-transition>
              <button
                class=" mx-4"
                style=" padding: 0"
                @click="toggleSideNav"
              >
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center ga-5 w-100 icon-text-contant">
                    <img
                      src="@/assets/soft-ware-img/leads-icon-side-bar.svg"
                      alt=""
                    />
                    <span>Leads</span>
                  </div>
                </div>
              </button>
              <button
                class=" mx-4"
                style=" padding: 0"
                @click="toggleSideNav"
              >
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center ga-5 w-100 icon-text-contant">
                    <img
                      src="@/assets/soft-ware-img/social-media-side-icon.svg"
                      alt=""
                    />
                    <span>Social media</span>
                  </div>
                  <p @click="dropDown('expand3')">
                    <span v-if="!expand3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="15"
                        height="15"
                        fill="rgba(31, 102, 106, 1)"
                      >
                        <path
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                        />
                      </svg>
                    </span>
                    <span v-else>
                      <svg
                        width="15"
                        height="15"
                        fill="rgba(31, 102, 106, 1)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                        />
                      </svg>
                    </span>
                  </p>
                </div>
              </button>
              <v-expand-transition>
                <v-card
                  v-show="expand3"
                  class="pl-10 pr-4 bg-transparent"
                  height=""
                  width="100%"
                  elevation="0"
                >
                  <router-link to="/">
                    <span>
                      <img
                        src="@/assets/soft-ware-img/discussion_14562101 1.svg"
                        alt=""
                      />
                    </span>
                    <span>Messages center</span>
                  </router-link>
                  <router-link to="/">
                    <span>
                      <img src="@/assets/soft-ware-img/review_768872 1.svg" alt="" />
                    </span>
                    <span>Publish posts</span>
                  </router-link>
                  <router-link to="/">
                    <span>
                      <img
                        src="@/assets/soft-ware-img/engegment-insites-icon-side.svg"
                        alt=""
                      />
                    </span>
                    <span>Engament and insights</span>
                  </router-link>
                </v-card>
              </v-expand-transition>

              <button
                class=" mx-4"
                style="padding: 0"
                @click="toggleSideNav"
              >
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center ga-5 w-100 icon-text-contant">
                    <img
                      src="@/assets/soft-ware-img/paint-palette_12094326 1.svg"
                      alt=""
                    />
                    <span>Designs</span>
                  </div>
                  <p @click="dropDown('expand4')">
                    <span v-if="!expand4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="15"
                        height="15"
                        fill="rgba(31, 102, 106, 1)"
                      >
                        <path
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                        />
                      </svg>
                    </span>
                    <span v-else>
                      <svg
                        width="15"
                        height="15"
                        fill="rgba(31, 102, 106, 1)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                        />
                      </svg>
                    </span>
                  </p>
                </div>
              </button>
              <v-expand-transition>
                <v-card
                  v-show="expand4"
                  class="pl-10 pr-4 bg-transparent"
                  height=""
                  width="100%"
                  elevation="0"
                >
                  <router-link to="/">
                    <span>
                      <img
                        src="@/assets/soft-ware-img/createDesign.svg"
                        alt=""
                      />
                    </span>
                    <span>Create Design</span>
                  </router-link>
                  <router-link to="/">
                    <span>
                      <img src="@/assets/soft-ware-img/createLandingPage.svg" alt="" />
                    </span>
                    <span>Create Landing Page</span>
                  </router-link>
                  <router-link to="/">
                    <span>
                      <img
                        src="@/assets/soft-ware-img/myDrive.svg"
                        alt=""
                      />
                    </span>
                    <span>My Drive</span>
                  </router-link>
                </v-card>
              </v-expand-transition>

              <button
                class=" mx-4"
                style=" padding: 0"
                @click="toggleSideNav"
              >
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center ga-5 w-100 icon-text-contant">
                    <img
                      src="@/assets/soft-ware-img/setting-icon.svg"
                      alt=""
                    />
                    <span @click="dropDown('expand5')" >Settings</span>
                  </div>
                  <p @click="dropDown('expand5')">
                    <span v-if="!expand5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="15"
                        height="15"
                        fill="rgba(31, 102, 106, 1)"
                      >
                        <path
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                        />
                      </svg>
                    </span>
                    <span v-else>
                      <svg
                        width="15"
                        height="15"
                        fill="rgba(31, 102, 106, 1)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                        />
                      </svg>
                    </span>
                  </p>
                </div>
              </button>
              <v-expand-transition>
                <v-card
                  v-show="expand5"
                  class="pl-10 pr-4 bg-transparent"
                  height=""
                  width="100%"
                  elevation="0"
                >
                  <router-link to="/">
                    <span>
                      <img
                        src="@/assets/soft-ware-img/team-icon.svg"
                        alt=""
                      />
                    </span>
                    <span>My Team </span>
                  </router-link>
                  <router-link to="/">
                    <span>
                      <img src="@/assets/soft-ware-img/Finance-center.svg" alt="" />
                    </span>
                    <span>Finance Center</span>
                  </router-link>
                  <router-link to="/">
                    <span>
                      <img
                        src="@/assets/soft-ware-img/integrations.svg"
                        alt=""
                      />
                    </span>
                    <span>Integrations</span>
                  </router-link>
                  <router-link to="/">
                    <span>
                      <img
                        src="@/assets/soft-ware-img/my-account-side-icon.svg"
                        alt=""
                      />
                    </span>
                    <span>My Account</span>
                  </router-link>
                </v-card>
              </v-expand-transition>

              <button class="logout"  type="button" >
                <img src="@/assets/soft-ware-img/logout-sidenav-icon.svg" alt="">
              </button>

            </div>
            
          </div>
        </v-col>

        <v-col cols="8" style="margin-top: 2%" class="taps">
          <v-card elevation="0">
            <v-tabs v-model="tab">
              <v-tab value="one">
                <img
                  src="@/assets/soft-ware-img/snapchat-logo-svgrepo-com.svg  "
                  alt=""
                />
                SnapChat
              </v-tab>
              <v-tab value="two">
                <img
                  src="@/assets/soft-ware-img/tiktok-svgrepo-com.svg"
                  alt=""
                />
                TikTok
              </v-tab>
              <v-tab value="three">
                <img src="@/assets/soft-ware-img/meta-svgrepo-com.svg" alt="" />
                Meta
              </v-tab>
              <v-tab value="foure">
                <img
                  src="@/assets/soft-ware-img/google-ads-svgrepo-com.svg"
                  alt=""
                />
                Google Ads
              </v-tab>
              <v-tab value="five">
                <img
                  style="height: 25px"
                  src="@/assets/soft-ware-img/X-Logo.svg"
                  alt=""
                />
              </v-tab>
            </v-tabs>
            <v-card-text
              style="
                background-color: rgba(234, 248, 248, 1);
                border-radius: 0 15px 15px 15px;
              "
            >
              <v-tabs-window v-model="tab">
                <v-tabs-window-item value="one">
                  <v-card
                    elevation="5"
                    rounded="lg"
                    class="pa-5 iframe-container"
                  >
                    <iframe
                      src="http://localhost:8080/snapchat"
                      style="width: 100%; height: 600px; overflow: hidden"
                      frameborder="0"
                    ></iframe>
                  </v-card>
                </v-tabs-window-item>

                <v-tabs-window-item value="two">
                  <v-card elevation="3" class="pa-5">
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Amet rem voluptas ab distinctio, provident harum neque
                      soluta assumenda ad officia magni id nam alias pariatur
                      commodi accusantium fugit temporibus ipsum?
                    </p>
                  </v-card>
                </v-tabs-window-item>

                <v-tabs-window-item value="three">
                  <v-card elevation="3" class="pa-5">
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Amet rem voluptas ab distinctio, provident harum neque
                      soluta assumenda ad officia magni id nam alias pariatur
                      commodi accusantium fugit temporibus ipsum?
                    </p>
                  </v-card>
                </v-tabs-window-item>

                <v-tabs-window-item value="foure">
                  <v-card elevation="3" class="pa-5">
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Amet rem voluptas ab distinctio, provident harum neque
                      soluta assumenda ad officia magni id nam alias pariatur
                      commodi accusantium fugit temporibus ipsum?
                    </p>
                  </v-card>
                </v-tabs-window-item>

                <v-tabs-window-item value="five">
                  <v-card elevation="3" class="pa-5">
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Amet rem voluptas ab distinctio, provident harum neque
                      soluta assumenda ad officia magni id nam alias pariatur
                      commodi accusantium fugit temporibus ipsum?
                    </p>
                  </v-card>
                </v-tabs-window-item>
              </v-tabs-window>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NavBar from "@/components/layout/GlobalNav.vue";
import "@/assets/css/style.css";
export default {
  components: { NavBar },
  data: () => ({
    tab: null,
    expand2: false,
    expand3: false,
    sideNavWidth: false,
    expand4:false,
    expand5:false,
  }),
  methods: {
    toggleSideNav() {
      this.sideNavWidth = !this.sideNavWidth;
      this.$refs.sideNavParent.style.width = "80%";
    },
    dropDown(property) {
      this[property]= !this[property];
    },
  },
};
</script>
