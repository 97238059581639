<template>
  <v-dialog
    v-model="create_customer_list_audiance_dialog"
    max-width="55%"
    persistent
  >
    <v-card class="pa-5 radius-10">
      <div>
        <span>Create New Customer List Audience</span>
      </div>

      <v-spacer></v-spacer>
      <div class="body-header">
        <p class="">
          Import emails, phone numbers, or mobile IDs. Reference one of the
          following examples as a formatting guide:
          <a class="blue-link" href="">Emails</a> ,<a class="blue-link" href=""
            >Phone Numbers</a
          >
          ,<a class="blue-link" href="">Mobile Ad IDs</a>
        </p>
        <p>
          <a href="" class="blue-link">Learn more about formatting your data</a>
        </p>
      </div>
      <div class="body mt-3">
        <div class="filed">
          <span class="title">Methods</span>
          <div class="options">
            <div class="tree-select d-flex ga-3">
              <div class="radio-button">
                <input
                  v-model="method_type"
                  value="upload_file"
                  name="method_type"
                  id="method_type-upload-file"
                  class="radio-button__input"
                  type="radio"
                />
                <label
                  for="method_type-upload-file"
                  class="radio-button__label"
                >
                  <span class="radio-button__custom"></span>
                  <span class="d-block">Upload File (.csv or .txt) </span>
                </label>
              </div>
              <div class="radio-button">
                <input
                  v-model="method_type"
                  value="copy_and_past"
                  name="method_type"
                  id="method_type-copy-and_past"
                  class="radio-button__input"
                  type="radio"
                />
                <label
                  for="method_type-copy-and_past"
                  class="radio-button__label"
                >
                  <span class="radio-button__custom"></span>
                  <span class="d-block">Copy and Paste</span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="method_type == 'upload_file'" class="upload-file">
            <label for="file" class="labelFile"
              ><span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#444546"
                  xmlns="http://www.w3.org/2000/svg"
                  class="upload-icon"
                >
                  <path
                    d="M2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667L2 9.33333L3.33333 9.33333L3.33333 12.6667L12.6667 12.6667L12.6667 9.33333L14 9.33333L14 12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14L3.33333 14C2.97971 14 2.64057 13.8595 2.39052 13.6095Z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.37337 4.27611L5.93141 5.71807L4.9886 4.77526L8.04001 1.72385L11.0914 4.77526L10.1486 5.71807L8.7067 4.27617L8.7067 11.3333L7.37337 11.3333L7.37337 4.27611Z"
                  ></path>
                </svg>
              </span>
              <p>drop file here or click to upload</p>
            </label>
            <input class="input-file" name="text" id="file" type="file" />
          </div>
          <div v-if="method_type == 'copy_and_past'" class="copy_and_past">
            <textarea
              v-model="past_and_copy"
              @paste="handlePaste"
              @keydown="clearOnBackspace"
              readonly
              placeholder="Paste You Data Here"
            ></textarea>
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Audiance Name</span>
          <input
            type="text"
            v-model="audiance_name"
            placeholder="Enter Your Audiance Name"
          />
          <div class="warning">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#444546"
              xmlns="http://www.w3.org/2000/svg"
              class="warning-svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
              ></path>
            </svg>
            <p class="small-text muted-text">
              The audience must be given a name
            </p>
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Data Type</span>
          <v-select
            v-model="data_type"
            class="v-select-style"
            :items="['Email', 'Phone', 'Mobile Ad ID']"
          ></v-select>
        </div>
        <div class="filed mt-3">
          <span class="title">Discription</span>
          <input v-model="description" type="text" />
        </div>
        <div class="policis muted-text mt-3">
          By clicking "Upload", I represent and warrant that I am authorized to
          bind Business and Advertiser and I agree on their behalf to the
          <a href="" class="yellow-link"
            >Snap Audience Match Terms and Conditions</a
          >
        </div>
      </div>
      <div class="mt-5 d-flex justify-end align-center ga-3">
        <a
          @click="create_customer_list_audiance_dialog = false"
          class="cancel-btn"
          >Cancel</a
        >

        <v-btn class="upload-button" :readonly="!upload_ready"> Upload </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateNewCustomerListAudience",
  data() {
    return {
      create_customer_list_audiance_dialog: false,
      audiance_name: "",
      method_type: "upload_file",
      past_and_copy: "",
      data_type: "",
      description: "",
      upload_ready: false,
    };
  },
  methods: {
    openDialog() {
      this.create_customer_list_audiance_dialog = true;
    },
    closeDialog() {
      this.create_customer_list_audiance_dialog = false;
    },
    handlePaste(event) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      this.past_and_copy = pastedText;
    },
    clearOnBackspace(event) {
      if (event.key === "Backspace") {
        this.past_and_copy = "";
      }
    },
  },
};
</script>
<style scoped>
.body-header {
  background: #f7f9fb;
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.blue-link {
  color: #3bf;
  text-decoration: none;
}
.blue-link:hover {
  color: #0894fa;
}
input {
  background-color: #f9f9fa;
  height: 38px;
}
.warning {
  display: flex;
  gap: 5px;
}
.warning-svg {
  display: flex;
  color: rgb(8, 148, 250);
  fill: rgb(8, 148, 250);
  flex-shrink: 0;
}
.filed .title {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.input-file {
  max-width: 190px;
  display: none;
}

.labelFile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  border: 1px dashed #d1d3d5;
  border-radius: 6px;
  color: #848688;
  cursor: pointer;
  display: inline-flex;
  height: 133px;
  padding: 24px;
  position: relative;
  background: #f7f9fb;
  border-width: 2px;
  text-align: center;
}
.labelFile:hover {
  background: #fff;
}
textarea {
  width: 100%;
  border-radius: 6px;
  color: #848688;
  cursor: pointer;
  height: 266px;
  padding: 24px;
  position: relative;
  background: #f7f9fb;
  border-width: 2px;
  text-align: start;
  border: 1px solid transparent;
}
textarea:hover {
  border: 1px solid #f7d901;
}
.upload-icon {
  color: #c3c5c7;
  display: block;
  margin: 0 auto 16px;
  fill: #c3c5c7;
  font-size: 24px;
}
.policis {
  font-size: 11px;
  margin-bottom: 24px;
  margin-top: 12px;
  text-align: start;
  text-wrap: nowrap;
}
.yellow-link {
  background-color: #f7d901;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.815);
}
.yellow-link:hover {
  background-color: unset;
  color: rgba(0, 0, 0, 0.815);
}

.cancel-btn {
  background: unset;
  border: unset;
  color: black;
  box-shadow: unset;
  background-color: unset;
  position: relative;
  padding-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
}

.cancel-btn .v-btn__content {
  position: relative;
}

.cancel-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: blue;
  transition: width 0.3s ease;
}

.cancel-btn:hover::after {
  width: 100%;
}

.cancel-btn:hover {
  color: blue;
}
.upload-button:hover {
  box-shadow: unset;
}
.upload-button {
  align-items: center;
  border: none;
  border-radius: 100px;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  justify-content: center;
  line-height: 1;
  min-height: 32px;
  outline: 1px;
  padding: 8px 20px;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  background: white;
  box-shadow: 0 0 0 1px #d1d3d5;
  color: #545557;
}
.upload-button.v-btn--readonly {
  background: #e1e3e5
    repeating-linear-gradient(
      45deg,
      #d1d3d5,
      #d1d3d5 1px,
      #e1e3e5 0,
      #e1e3e5 5px
    );
}
.upload-button:hover .v-btn__overlay {
  opacity: 0px;
}
</style>
