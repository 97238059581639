<template>
  <h1>Third</h1>
  <p>
    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quod dolorem atque
    nihil, doloremque iusto distinctio, omnis quo nemo explicabo corrupti a
    consectetur fugit deleniti illum iure aliquam veniam suscipit quidem.
  </p>
</template>

<script>
export default {};
</script>
