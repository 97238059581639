<template>
  <div class="ad-set-details secound-step">
    <div class="main-contain-inputs">
      <!-- Ad Set Details -->
      <div class="ad-set-details-card mb-6">
        <h6 class="page-header-title mb-3">Ad Set Details</h6>
        <v-card max-width="100%" class="pa-5 mb-5 rounded-lg" elevation="1">
          <div class="card-header">
            <input-label class="mb-3" :campaignName="campaignName" type="text" :labeltext="labeltext"></input-label>
            <div class="tree-select">
              <div class="conversion-location">
                <span class="conversion-location-title">
                  Conversion Location
                </span>
                <div class="conversion-location-text">
                  hoose where you want your ad to direct traffic. You'll add
                  more details about the destination later on.
                </div>
              </div>
              <div class="radio-button">
                <input v-model="directTrafficLocation" value="ac-conversion-location-WEB" name="ac-conversion-location"
                  id="ac-conversion-location-WEB" class="radio-button__input" type="radio" />
                <label for="ac-conversion-location-WEB" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">Web</span>
                  <span class="d-block small-text">
                    Drive traffic to your website.
                  </span>
                </label>
              </div>
              <div class="radio-button">
                <input v-model="directTrafficLocation" value="ac-conversion-location-APP" name="ac-conversion-location"
                  id="ac-conversion-location-APP" class="radio-button__input" type="radio" />
                <label for="ac-conversion-location-APP" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">App</span>
                  <span class="d-block small-text">
                    Drive traffic to your app.
                  </span>
                </label>
              </div>
              <div v-if="appSelected" class="app-warning-alert">
                <div class="body">
                  <div class="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546" xmlns="http://www.w3.org/2000/svg"
                      class="warning-icon-svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M1.99994 8C1.99994 4.68629 4.68623 2 7.99994 2C11.3136 2 13.9999 4.68629 13.9999 8C13.9999 11.3137 11.3136 14 7.99994 14C4.68623 14 1.99994 11.3137 1.99994 8Z">
                      </path>
                      <path
                        d="M7.16653 10.755C7.16521 10.5899 7.21296 10.4281 7.30372 10.2902C7.39448 10.1523 7.52416 10.0444 7.67631 9.98034C7.82847 9.91625 7.99623 9.89879 8.15831 9.93019C8.3204 9.96158 8.46951 10.0404 8.58672 10.1567C8.70393 10.273 8.78397 10.4214 8.81666 10.5833C8.84936 10.7451 8.83325 10.913 8.77038 11.0657C8.70751 11.2183 8.60071 11.3489 8.46352 11.4407C8.32634 11.5326 8.16496 11.5816 7.99986 11.5816C7.89074 11.5825 7.78253 11.5618 7.68146 11.5206C7.5804 11.4795 7.48848 11.4187 7.41101 11.3419C7.33354 11.265 7.27205 11.1736 7.23009 11.0729C7.18813 10.9721 7.16653 10.8641 7.16653 10.755Z"
                        fill="white"></path>
                      <path
                        d="M8.00001 3.79333C8.11321 3.7941 8.22508 3.81775 8.32891 3.86284C8.43274 3.90793 8.52638 3.97354 8.60422 4.05574C8.68205 4.13793 8.74247 4.23501 8.78185 4.34114C8.82122 4.44727 8.83874 4.56026 8.83334 4.67333L8.50001 9.04H7.49334L7.16668 4.67333C7.16222 4.56049 7.18038 4.44789 7.22009 4.34217C7.25981 4.23645 7.32027 4.13975 7.39792 4.05775C7.47557 3.97575 7.56884 3.91011 7.67225 3.8647C7.77565 3.81929 7.88709 3.79503 8.00001 3.79333Z"
                        fill="white"></path>
                    </svg>
                  </div>
                  <div class="text">
                    We haven’t found any Snap App IDs associated with this ad
                    account. Make sure to authenticate your app in
                    <a href="#">Business Manager</a> and associate it with this
                    ad account.
                  </div>
                </div>
              </div>
              <div class="radio-button">
                <input v-model="directTrafficLocation" value="ac-conversion-location-MY_PROFILE"
                  name="ac-conversion-location" id="ac-conversion-location-MY_PROFILE" class="radio-button__input"
                  type="radio" />
                <label for="ac-conversion-location-MY_PROFILE" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">My Public Profile</span>
                  <span class="d-block small-text">Get Snapchatters to visit your public profile.
                  </span>
                </label>
              </div>
              <div class="radio-button">
                <input v-model="directTrafficLocation" value="ac-conversion-location-CALL" name="ac-conversion-location"
                  id="ac-conversion-location-CALL" class="radio-button__input" type="radio" />
                <label for="ac-conversion-location-CALL" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">Call</span>
                  <span class="d-block small-text">Get Snapchatters to call your business.
                  </span>
                </label>
              </div>
              <div class="radio-button">
                <input v-model="directTrafficLocation" value="ac-conversion-location-TEXT" name="ac-conversion-location"
                  id="ac-conversion-location-TEXT" class="radio-button__input" type="radio" />
                <label for="ac-conversion-location-TEXT" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">Text</span>
                  <span class="d-block small-text">Get Snapchatters to text your business.
                  </span>
                </label>
              </div>
            </div>
            <label for="" class="d-block status-title">Status</label>
            <switch-toogle @tooglePaused="toogleTextPaused" paused="false"></switch-toogle>
          </div>
        </v-card>
      </div>
      <!-- Ad Set Details -->
      <div class="ad-set-details-card mb-6">
        <h6 class="page-header-title mb-3">Ad Set Details</h6>
        <v-card max-width="100%" class="pa-5 mb-5 rounded-lg" elevation="1">
          <div class="card-header">
            <input-label class="mb-3" :campaignName="campaignName" type="text" :labeltext="labeltext"></input-label>

            <label for="" class="d-block status-title">Status</label>
            <switch-toogle @tooglePaused="toogleTextPaused" paused="false"></switch-toogle>
          </div>
        </v-card>
      </div>
      <!-- Tracking  -->
      <div class="tracking-card ad-set-details-card mb-6">
        <h6 class="page-header-title mb-3">Tracking</h6>
        <v-card max-width="100%" class="pa-5 mb-5 rounded-lg" elevation="1">
          <div class="card-header">
            <label for="" class="d-block status-title">Snap Pixel </label>
            <switch-toogle @toggleAttached="toggleAttached" notAttached="false"></switch-toogle>
            <div class="mb-4"></div>
            <label for="" class="d-block status-title">Snap <strong>App ID Tracking</strong>
            </label>
            <switch-toogle @toggleAlertForApp="alertCard"></switch-toogle>
            <div class="alert-for-app mt-5" v-if="alertForApp">
              <div class="warnning-card">
                <h4>Have you added your app?</h4>
                <p>
                  We haven’t found any Snap App IDs associated with this ad
                  account. Make sure to authenticate your app in
                  <a href="#">Business Manager</a>
                  and associate it with this ad account.
                </p>
                <p>
                  App events tracking via Snap App ID unlocks several benefits
                  such as Mobile App Custom Audiences or App Re-engagement
                  Optimization Goals. This is optional but recommended.
                </p>
                <a href="https://businesshelp.snapchat.com/s/article/snap-app-id?language=en_US">Learn More about Snap
                  App IDs</a>
              </div>
              <div class="snap-app">
                <label for="">Snap App ID </label>
                <v-select variant="solo" density="compact" style="
                    background: #e1e3e5
                      repeating-linear-gradient(
                        45deg,
                        #d1d3d5,
                        #d1d3d5 2px,
                        #e1e3e5 0,
                        #e1e3e5 10px
                      );
                  " disabled></v-select>
                <p class="app-id-validate mb-5">
                  <span class="d-block">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546" xmlns="http://www.w3.org/2000/svg"
                      class="sds-icon sds-icon-incomplete sds-input-status styles-1hnbkxj">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z">
                      </path>
                    </svg>
                  </span>
                  Snap App ID is required
                </p>
                <label class="d-block mb-1" for=""><strong>SKAdNetwork</strong>
                </label>
                <switch-toogle disabled="true"></switch-toogle>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <!-- Goal & Bid -->
      <div class="goal-and-bid ad-set-details-card mb-6">
        <h6 class="page-header-title mb-3">Goal & Bid</h6>
        <v-card max-width="100%" class="pa-5 mb-5 rounded-lg" style="overflow: visible" elevation="1">
          <div class="card-header">
            <label for="">Performance Goal </label>
            <TreeSelect />
            <div class="bid-parent position-relative">
              <label for="">Bid</label>
              <div :class="bidContant ? 'border-bottom' : ''"
                class="select-bid-way d-flex align-center justify-space-between" @click="togglebidContant">
                <p>{{ bidSelectedView }}</p>
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546" xmlns="http://www.w3.org/2000/svg"
                    class="sds-icon sds-icon-chevron-down sds-dropdown-arrow">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.04903 8.94148L11.8176 5.17288L12.7604 6.11569L8.04903 10.8271L3.33763 6.11569L4.28044 5.17288L8.04903 8.94148Z">
                    </path>
                  </svg>
                </span>
              </div>
              <div class="select-bid-contant" v-if="bidContant">
                <ul>
                  <li v-for="videoGoal in paiedType" :key="videoGoal.id">
                    <div class="radio-button">
                      <input @change="bidContant = false" name="tree-select" :id="'radio' + videoGoal.id"
                        class="radio-button__input" type="radio" :value="videoGoal.title" v-model="bidSelectedView" />
                      <label :for="'radio' + videoGoal.id" class="radio-button__label">
                        <span class="radio-button__custom"></span>
                        <p class="d-flex align-center ga-1">
                          <span class="d-block select-title" style="color: rgb(0, 0, 0); font-size: 14px">{{
                            videoGoal.title }}</span>
                          <span v-if="videoGoal.autoPaied" class="recomended">{{
                            videoGoal.autoPaied
                            }}</span>
                        </p>
                        <span class="d-block select-description" style="color: rgb(84, 85, 87); font-size: 12px">
                          {{ videoGoal.description }}
                        </span>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-if="isMaxBidSelected">
                <div class="max-bid-amount mt-3 d-flex align-center justify-space-between">
                  <input @input="toggleValidate" min="0" class="no-spin" type="number" name="" id=""
                    v-model="bidPer15secound" value="" />
                  <span class="bid-per"> Bid is per 15 Second Video View</span>
                </div>
                <div class="description-max-bid">
                  <span v-if="validateMaxBid" class="validate d-flex align-center ga-2">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546" xmlns="http://www.w3.org/2000/svg"
                      class="sds-icon sds-icon-incomplete sds-input-status styles-1hnbkxj">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z">
                      </path>
                    </svg>
                    Maximum bid requires bid value
                  </span>
                  <p class="mt-6">
                    <span>Suggested bid $0.20 - $0.57 </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <!-- Budget & Schedule  -->
      <div class="budget-and-schedule ad-set-details-card mb-6">
        <h6 class="page-header-title mb-3">Budget & Schedule</h6>
        <v-card max-width="100%" class="pa-5 mb-5 rounded-lg budget-and-schedule-card" elevation="1">
          <div class="card-header">
            <span class="inputs-title">Budget</span>
            <div class="d-2 ga-3">
              <currency-input></currency-input>
              <v-select variant="solo" class="v-select-style" v-model="SelectedBudget" :items="budget"
                item-title="title" item-value="value"></v-select>
            </div>
            <!-- daily_budget  -->
            <div v-if="SelectedBudget == 'daily_budget'" class="lifetime-baget-container">
              <span class="inputs-title mt-5">Starts & Ends</span>
              <div class="d-3-1 ga-3 mt-2">
                <input class="custom-input" type="date" />
                <input class="custom-input" type="time" />
              </div>
              <div class="d-flex justify-end pacific-time mt-1">
                Pacific time
              </div>
              <div class="optoinal-daily-end-time-checkbox">
                <label class="snap-checkbox">
                  <input v-model="daily_budget_end_time" type="checkbox" />
                  <span class="snap-checkmark"></span>Set an End Date (Optional)
                </label>
              </div>
              <div v-if="daily_budget_end_time" class="optoinal-daily-end-time">
                <div class="d-3-1 ga-3 mt-2">
                  <input v-model="daily_budget_end_date" class="custom-input" type="date" />
                  <input :disabled="!daily_budget_end_date" class="custom-input daily_budget_end_time" type="time" />
                </div>
                <div class="d-flex justify-end pacific-time mt-1 w-100">
                  Pacific time
                </div>
                <div v-if="daily_budget_end_date_warning" class="three-days-end-time-warning">
                  <div class="header">
                    <div class="icon">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546" xmlns="http://www.w3.org/2000/svg"
                        class="warning-icon-svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M1.99994 8C1.99994 4.68629 4.68623 2 7.99994 2C11.3136 2 13.9999 4.68629 13.9999 8C13.9999 11.3137 11.3136 14 7.99994 14C4.68623 14 1.99994 11.3137 1.99994 8Z">
                        </path>
                        <path
                          d="M7.16653 10.755C7.16521 10.5899 7.21296 10.4281 7.30372 10.2902C7.39448 10.1523 7.52416 10.0444 7.67631 9.98034C7.82847 9.91625 7.99623 9.89879 8.15831 9.93019C8.3204 9.96158 8.46951 10.0404 8.58672 10.1567C8.70393 10.273 8.78397 10.4214 8.81666 10.5833C8.84936 10.7451 8.83325 10.913 8.77038 11.0657C8.70751 11.2183 8.60071 11.3489 8.46352 11.4407C8.32634 11.5326 8.16496 11.5816 7.99986 11.5816C7.89074 11.5825 7.78253 11.5618 7.68146 11.5206C7.5804 11.4795 7.48848 11.4187 7.41101 11.3419C7.33354 11.265 7.27205 11.1736 7.23009 11.0729C7.18813 10.9721 7.16653 10.8641 7.16653 10.755Z"
                          fill="white"></path>
                        <path
                          d="M8.00001 3.79333C8.11321 3.7941 8.22508 3.81775 8.32891 3.86284C8.43274 3.90793 8.52638 3.97354 8.60422 4.05574C8.68205 4.13793 8.74247 4.23501 8.78185 4.34114C8.82122 4.44727 8.83874 4.56026 8.83334 4.67333L8.50001 9.04H7.49334L7.16668 4.67333C7.16222 4.56049 7.18038 4.44789 7.22009 4.34217C7.25981 4.23645 7.32027 4.13975 7.39792 4.05775C7.47557 3.97575 7.56884 3.91011 7.67225 3.8647C7.77565 3.81929 7.88709 3.79503 8.00001 3.79333Z"
                          fill="white"></path>
                      </svg>
                    </div>
                    <div class="text">
                      Increase Ad Set Duration for Better Results
                    </div>
                  </div>
                  <div class="body">
                    After you create a new ad set, our system will start the
                    learning phase to best deliver your ads. For optimal
                    performance, we recommend that your ad set runs for at least
                    7 days.
                  </div>
                </div>
              </div>
            </div>
            <!-- lifetime_budget  -->
            <div v-if="SelectedBudget == 'lifetime_budget'" class="lifetime-baget-container">
              <span class="inputs-title mt-5">Starts & Ends</span>
              <div class="d-3-1 ga-3 mt-2">
                <input class="custom-input" type="date" />
                <input class="custom-input" type="time" />
              </div>
              <div class="d-flex justify-end pacific-time mt-1">
                Pacific time
              </div>
              <div class="d-3-1 ga-3 mt-2">
                <input v-model="daily_budget_end_date" class="custom-input" type="date" />
                <input class="custom-input" type="time" />
              </div>
              <div class="d-flex justify-end pacific-time mt-1">
                Pacific time
              </div>
              <div v-if="daily_budget_end_date_warning" class="three-days-end-time-warning">
                <div class="header">
                  <div class="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546" xmlns="http://www.w3.org/2000/svg"
                      class="warning-icon-svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M1.99994 8C1.99994 4.68629 4.68623 2 7.99994 2C11.3136 2 13.9999 4.68629 13.9999 8C13.9999 11.3137 11.3136 14 7.99994 14C4.68623 14 1.99994 11.3137 1.99994 8Z">
                      </path>
                      <path
                        d="M7.16653 10.755C7.16521 10.5899 7.21296 10.4281 7.30372 10.2902C7.39448 10.1523 7.52416 10.0444 7.67631 9.98034C7.82847 9.91625 7.99623 9.89879 8.15831 9.93019C8.3204 9.96158 8.46951 10.0404 8.58672 10.1567C8.70393 10.273 8.78397 10.4214 8.81666 10.5833C8.84936 10.7451 8.83325 10.913 8.77038 11.0657C8.70751 11.2183 8.60071 11.3489 8.46352 11.4407C8.32634 11.5326 8.16496 11.5816 7.99986 11.5816C7.89074 11.5825 7.78253 11.5618 7.68146 11.5206C7.5804 11.4795 7.48848 11.4187 7.41101 11.3419C7.33354 11.265 7.27205 11.1736 7.23009 11.0729C7.18813 10.9721 7.16653 10.8641 7.16653 10.755Z"
                        fill="white"></path>
                      <path
                        d="M8.00001 3.79333C8.11321 3.7941 8.22508 3.81775 8.32891 3.86284C8.43274 3.90793 8.52638 3.97354 8.60422 4.05574C8.68205 4.13793 8.74247 4.23501 8.78185 4.34114C8.82122 4.44727 8.83874 4.56026 8.83334 4.67333L8.50001 9.04H7.49334L7.16668 4.67333C7.16222 4.56049 7.18038 4.44789 7.22009 4.34217C7.25981 4.23645 7.32027 4.13975 7.39792 4.05775C7.47557 3.97575 7.56884 3.91011 7.67225 3.8647C7.77565 3.81929 7.88709 3.79503 8.00001 3.79333Z"
                        fill="white"></path>
                    </svg>
                  </div>
                  <div class="text">
                    Increase Ad Set Duration for Better Results
                  </div>
                </div>
                <div class="body">
                  After you create a new ad set, our system will start the
                  learning phase to best deliver your ads. For optimal
                  performance, we recommend that your ad set runs for at least 7
                  days.
                </div>
              </div>
              <!-- Ad Scheduling  -->
              <div class="ad-scheduling tree-select">
                <div class="radio-button">
                  <input v-model="ad_scheduling_time" value="ad-scheduling-all-the-time" name="ad-scheduling"
                    id="ad-scheduling-all-the-time" class="radio-button__input" type="radio" />
                  <label for="ad-scheduling-all-the-time" class="radio-button__label">
                    <span class="radio-button__custom"></span>
                    <span class="d-block">Run Ads All The Time</span>
                  </label>
                </div>
                <div class="radio-button">
                  <input v-model="ad_scheduling_time" value="ad-scheduling-at-scheduling-time" name="ad-scheduling"
                    id="ad-scheduling-at-scheduling-time" class="radio-button__input" type="radio" />
                  <label for="ad-scheduling-at-scheduling-time" class="radio-button__label">
                    <span class="radio-button__custom"></span>
                    <span class="d-block">Run ads on a schedule
                      <span class="text-muted small-text">Ads are scheduled based on the time zone of the person
                        seeing it.</span>
                    </span>
                  </label>
                </div>
                <div class="" v-if="
                  ad_scheduling_time == 'ad-scheduling-at-scheduling-time'
                ">
                  <div class="">
                    <ScheduleCalender />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <!-- Placements -->
      <div class="placements ad-set-details-card mb-6 fs-14">
        <h6 class="page-header-title mb-3">Placements</h6>
        <v-card max-width="100%" class="pa-5 mb-5 rounded-lg" style="overflow: visible" elevation="1">
          <div class="card-body">
            <div class="placements tree-select">
              <!-- automatic_placement  -->
              <div class="radio-button">
                <input v-model="placement_type" value="automatic_placement" name="placement_type"
                  id="automatic_placement" class="radio-button__input" type="radio" />
                <label for="automatic_placement" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">Automatic Placement (Recommended)</span>
                  <span class="d-block small-text">
                    Run your ads across all of Snapchat. This gives more
                    flexibility to improve the results of your campaign and
                    maximize your reach.
                  </span>
                </label>
              </div>
              <!-- edit_placement  -->
              <div class="radio-button">
                <input v-model="placement_type" value="edit_placement" name="placement_type" id="edit_placement"
                  class="radio-button__input" type="radio" />
                <label for="edit_placement" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">Edit Placement</span>
                </label>
              </div>
            </div>
            <!-- edit_placement_options  -->
            <div v-if="placement_type == 'edit_placement'" class="edit-placement-selections">
              <div class="edit-placement-selections-tree tree-select ml-7">
                <!-- content  -->
                <div class="radio-button">
                  <input v-model="edit_placement_type" value="content" name="edit_placement_content"
                    id="edit_placement_content" class="radio-button__input" type="radio" />
                  <label for="edit_placement_content" class="radio-button__label">
                    <span class="radio-button__custom"></span>
                    <span class="d-block">content</span>
                  </label>
                  <!-- content options  -->
                  <div v-if="edit_placement_type == 'content'" class="fs-14 ml-7">
                    <!-- between content  -->
                    <div class="">
                      <div class="d-flex align-center justify-start ga-2">
                        <label class="snap-checkbox">
                          <input v-model="between_content" type="checkbox" />
                          <span class="snap-checkmark"></span>Between content
                          <span class="text-muted small-text">(Ads watched between stories and professional
                            content)</span>
                        </label>
                      </div>
                      <!-- between options  -->
                      <div class="between-options ml-7">
                        <div class="d-flex align-center justify-start ga-2">
                          <label class="snap-checkbox">
                            <input v-model="between_content_user_stories" type="checkbox" />
                            <span class="snap-checkmark"></span>User Stories
                          </label>
                        </div>
                        <div class="d-flex align-center justify-start ga-2">
                          <label class="snap-checkbox">
                            <input v-model="between_content_publisher_and_creator_stories
                              " type="checkbox" />
                            <span class="snap-checkmark"></span>Publisher &
                            Creator Stories
                          </label>
                        </div>
                        <div class="d-flex align-center justify-start ga-2">
                          <label class="snap-checkbox">
                            <input v-model="between_content_spotlight" type="checkbox" />
                            <span class="snap-checkmark"></span>Spotlight
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- within content -->
                    <div class="">
                      <div class="d-flex align-center justify-start ga-2">
                        <label class="snap-checkbox">
                          <input v-model="within_content" type="checkbox" />
                          <span class="snap-checkmark"></span>Within content
                          <span class="text-muted small-text">(Ads watched within professional, creator & curated
                            content)</span>
                        </label>
                      </div>
                      <!-- within options  -->
                      <div class="within-options ml-7">
                        <!-- within publisher stories  -->
                        <div class="publisher-stories">
                          <div class="d-flex align-center justify-start ga-2">
                            <label class="snap-checkbox">
                              <input v-model="within_content_publisher_stories" type="checkbox" />
                              <span class="snap-checkmark"></span>Publisher
                              Stories
                            </label>
                          </div>
                          <div class="ml-7">
                            <div class="fs-14">Categories</div>
                            <div class="d-1-3 ga-2">
                              <v-select variant="solo" class="v-select-style"  v-model="publisherStoriesSelectedCategoriesTypes"
                                :items="publisherStoriesCategoriesTypes"></v-select>
                              <v-select variant="solo" class="v-select-style" 
                                v-model="publisherStoriesSelectedCategories"
                                :items="publisherStoriesCategories"></v-select>
                            </div>
                          </div>
                        </div>
                        <!-- within creator stories  -->
                        <div class="creator-stories">
                          <div class="d-flex align-center justify-start ga-2">
                            <label class="snap-checkbox">
                              <input v-model="within_content_creator_stories" type="checkbox" />
                              <span class="snap-checkmark"></span>Creator
                              Stories
                            </label>
                          </div>
                          <div class="ml-7">
                            <div class="fs-14">Categories</div>
                            <div class="d-1-3 ga-2">
                              <v-select variant="solo" class="v-select-style" 
                                v-model="creatorStoriesSelectedCategoriesTypes"
                                :items="creatorStoriesCategoriesTypes"></v-select>
                              <v-select variant="solo" class="v-select-style" 
                                v-model="creatorStoriesSelectedCategories" :items="creatorStoriesCategories"></v-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- camera  -->
                <div class="radio-button">
                  <input v-model="edit_placement_type" value="camera" name="edit_placement_camera"
                    id="edit_placement_camera" class="radio-button__input" type="radio" />
                  <label for="edit_placement_camera" class="radio-button__label">
                    <span class="radio-button__custom"></span>
                    <span class="d-block">Camera</span>
                  </label>
                </div>
                <div v-if="edit_placement_type == 'camera'" class="ml-7">
                  <div class="d-flex align-center justify-start ga-2">
                    <label class="snap-checkbox">
                      <input v-model="camera_ar_lens" type="checkbox" />
                      <span class="snap-checkmark"></span>AR lens
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="more-options-parent">
            <button type="button" class="more-options mb-2" @click="moreOptionFun">
              <p v-if="!moreOptionsBtn">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#0894fa" width="13" height="13" viewBox="0 0 512 512">
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </span>
                <span> More </span>
              </p>
              <p v-else>
                <span>
                  <svg fill="#0894fa" width="13" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                  </svg>
                </span>
                <span> Less </span>
              </p>
            </button>
            <div v-show="moreOptions" class="more-options-contenant d-none px-1 mt-4" ref="moreOptionsContenant">
              <span class="fs-14">Brand Safety Filter</span>
              <div class="select-with-caption mt-3">
                <div :class="'option ' +
                  brand_safety_filter_active_fully +
                  ' tree-select mb-3'
                  ">
                  <div class="radio-button">
                    <input v-model="brand_safety_filter" value="full_inventory" name="full_inventory"
                      id="full_inventory" class="radio-button__input" type="radio" />
                    <label for="full_inventory" class="radio-button__label">
                      <span class="radio-button__custom"></span>
                      <span class="d-block">Full Inventory</span>
                      <span class="d-block small-text">
                        Show ads near any Snapchat content to maximize reach.
                        Content still adheres to Snap's Community Guidelines and
                        content moderation rules.
                      </span>
                    </label>
                  </div>
                </div>
                <div :class="'option ' +
                  brand_safety_filter_active_limited +
                  ' tree-select'
                  ">
                  <div class="radio-button">
                    <input :disabled="edit_placement_type == 'camera'" v-model="brand_safety_filter"
                      value="limited_inventory" name="limited_inventory" id="limited_inventory"
                      class="radio-button__input" type="radio" />
                    <label for="limited_inventory" class="radio-button__label">
                      <span class="radio-button__custom"></span>
                      <span class="d-block">Limited Inventory</span>
                      <span class="d-block small-text">
                        Show ads near content that has passed additional
                        moderation filters. This may decrease reach and increase
                        costs. Only available for Creator Stories right now.
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>

      <!-- Demographics  -->
      <div class="demographics ad-set-details-card mb-6 fs-14">
        <h6 class="page-header-title mb-3">Demographics</h6>
        <v-card max-width="100%" class="pa-5 mb-5 rounded-lg" style="overflow: visible" elevation="1">
          <div class="card-body">
            <span class="inputs-title">Regulated Content</span>
            <div class="">
              <label class="snap-checkbox">
                <input v-model="age_restrictions_required" type="checkbox" />
                <span class="snap-checkmark"></span>Age restrictions required
              </label>
              <div class="d-flex align-center justify-start ga-2"></div>

              <div class="">
                <label for="">Age</label>
                <div class="d-1-0-1 ga-2 w-50">
                  <v-select class="v-select-style" v-model="age_restrictions_required_start_age"
                    :items="age_restrictions_required_start_age_items" variant="solo"></v-select>

                  <span class="d-flex align-center justify-center">-</span>
                  <v-select variant="solo" class="v-select-style" v-model="age_restrictions_required_start_end_age"
                    :items="age_restrictions_required_start_end_age_items"></v-select>
                </div>
                <span v-if="show_age_restrictions_required_validate"
                  class="d-flex age_restrictions_required_validate mt-2">
                  <div class="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546" xmlns="http://www.w3.org/2000/svg"
                      class="age_restrictions_required_validate_svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M1.99994 8C1.99994 4.68629 4.68623 2 7.99994 2C11.3136 2 13.9999 4.68629 13.9999 8C13.9999 11.3137 11.3136 14 7.99994 14C4.68623 14 1.99994 11.3137 1.99994 8Z">
                      </path>
                      <path
                        d="M7.16653 10.755C7.16521 10.5899 7.21296 10.4281 7.30372 10.2902C7.39448 10.1523 7.52416 10.0444 7.67631 9.98034C7.82847 9.91625 7.99623 9.89879 8.15831 9.93019C8.3204 9.96158 8.46951 10.0404 8.58672 10.1567C8.70393 10.273 8.78397 10.4214 8.81666 10.5833C8.84936 10.7451 8.83325 10.913 8.77038 11.0657C8.70751 11.2183 8.60071 11.3489 8.46352 11.4407C8.32634 11.5326 8.16496 11.5816 7.99986 11.5816C7.89074 11.5825 7.78253 11.5618 7.68146 11.5206C7.5804 11.4795 7.48848 11.4187 7.41101 11.3419C7.33354 11.265 7.27205 11.1736 7.23009 11.0729C7.18813 10.9721 7.16653 10.8641 7.16653 10.755Z"
                        fill="white"></path>
                      <path
                        d="M8.00001 3.79333C8.11321 3.7941 8.22508 3.81775 8.32891 3.86284C8.43274 3.90793 8.52638 3.97354 8.60422 4.05574C8.68205 4.13793 8.74247 4.23501 8.78185 4.34114C8.82122 4.44727 8.83874 4.56026 8.83334 4.67333L8.50001 9.04H7.49334L7.16668 4.67333C7.16222 4.56049 7.18038 4.44789 7.22009 4.34217C7.25981 4.23645 7.32027 4.13975 7.39792 4.05775C7.47557 3.97575 7.56884 3.91011 7.67225 3.8647C7.77565 3.81929 7.88709 3.79503 8.00001 3.79333Z"
                        fill="white"></path>
                    </svg>
                  </div>
                  <div class="text">Age restrictions required</div>
                </span>
                <div v-if="age_restrictions_required" class="age_restrictions_required_warning mt-2">
                  <div class="d-flex ga-3 align-start justify-start">
                    <div class="icon">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546" xmlns="http://www.w3.org/2000/svg"
                        class="blue-warning-svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M1.99985 7.9999C1.99985 4.68614 4.68617 1.99982 7.99993 1.99982C11.3137 1.99982 14 4.68614 14 7.9999C14 11.3137 11.3137 14 7.99993 14C4.68617 14 1.99985 11.3137 1.99985 7.9999Z">
                        </path>
                        <path
                          d="M9.25189 10.8265C8.75462 11.53 8.00008 12.0091 7.15189 12.1599C6.75189 12.1599 6.53856 11.7999 6.72523 10.9599C6.95856 9.99321 7.23189 8.95988 7.39189 8.15988C7.47856 7.81321 7.46523 7.70654 7.39189 7.70654C7.13981 7.77876 6.91034 7.91415 6.72523 8.09988L6.54523 7.71321C7.09596 7.10032 7.8346 6.68762 8.64523 6.53988C9.05189 6.53988 9.14523 6.91321 8.88523 8.02654C8.70523 8.77321 8.43856 9.80654 8.25856 10.5465C8.17856 10.8465 8.17856 11.0199 8.25856 11.0199C8.5634 10.902 8.83224 10.7067 9.03856 10.4532L9.25189 10.8265ZM9.45189 4.82654C9.45545 4.9465 9.43533 5.06598 9.39268 5.17815C9.35004 5.29032 9.2857 5.39299 9.20336 5.48029C9.12101 5.56759 9.02227 5.6378 8.91278 5.68692C8.80328 5.73604 8.68518 5.76309 8.56523 5.76654C8.4651 5.77742 8.36382 5.76546 8.26897 5.73158C8.17413 5.6977 8.0882 5.64277 8.01763 5.57091C7.94706 5.49906 7.89368 5.41215 7.86152 5.31671C7.82935 5.22128 7.81922 5.11979 7.83189 5.01988C7.8354 4.77289 7.93598 4.53721 8.11188 4.3638C8.28779 4.19039 8.52488 4.09319 8.77189 4.09321C8.86584 4.09095 8.95921 4.10857 9.04586 4.14493C9.13252 4.18129 9.21051 4.23555 9.27473 4.30417C9.33894 4.37279 9.38791 4.4542 9.41845 4.54308C9.44898 4.63196 9.46038 4.72629 9.45189 4.81988V4.82654Z"
                          fill="white"></path>
                      </svg>
                    </div>
                    <div class="text">
                      Given you have declared your ad content is regulated
                      content please carefully select the legally allowed age
                      targeting in your country or region. Please reference our
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label for="">Gender</label>
                <div class="d-1-0-1 ga-2 w-25 gender-container">
                  <v-btn @click="demographics_gender = 'all'" :active="demographics_gender === 'all'"
                    class="gender-button">All</v-btn>
                  <v-btn @click="demographics_gender = 'male'" :active="demographics_gender === 'male'"
                    class="gender-button">Male</v-btn>
                  <v-btn @click="demographics_gender = 'female'" :active="demographics_gender === 'female'"
                    class="gender-button">Female</v-btn>
                </div>
              </div>
              <div class="mt-3">
                <v-select label="" class="v-select-style" v-model="selected_language" :items="languages"
                  item-title="name" item-value="id" return-object multiple="multiple" variant="solo"></v-select>
                <div class="mt-2">
                  <div class="" v-for="(language, index) in selected_language" :key="index">
                    <div class="d-flex ga-2 selected-language-button">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546" xmlns="http://www.w3.org/2000/svg"
                        class="correct-svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z">
                        </path>
                      </svg>
                      <span>{{ language.name }}</span>
                      <button class="remove-btn" @click="removeLangFromList(index)">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546"
                          xmlns="http://www.w3.org/2000/svg" class="sds-icon sds-icon-cross">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z">
                          </path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <!-- Audiences  -->
      <div class="audiences ad-set-details-card mb-6 fs-14">
        <h6 class="page-header-title mb-3">audiences</h6>
        <v-card max-width="100%" class="pa-5 mb-5 rounded-lg" style="overflow: visible" elevation="1">
          <div class="card-body">
            <span class="inputs-title">Predefined Audiences </span>
            <div class="">
              <div class="mt-2">
                <div class="selected-predefined-audiences-container">
                  <div class="" v-for="(
                      predefined_audience, index
                    ) in selected_predefined_audiences" :key="index">
                    <div class="d-flex justify-space-between align-center ga-2 selected-predefined-audiences">
                      <div class="">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546"
                          xmlns="http://www.w3.org/2000/svg" class="correct-svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z">
                          </path>
                        </svg>
                        <span>{{ predefined_audience.name }}</span>
                      </div>
                      <button class="remove-btn" @click="removeLangFromList(index)">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="#444546"
                          xmlns="http://www.w3.org/2000/svg" class="sds-icon sds-icon-cross">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z">
                          </path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <v-select label="" class="v-select-style" v-model="selected_predefined_audiences"
                  :items="predefined_audiences" item-title="name" item-value="id" return-object multiple="multiple"
                  variant="solo"></v-select>
              </div>
            </div>

            <span class="inputs-title">Custom Audiences</span>
            <div class="">
              <span>No Audience Available</span>
              <span>
                <button class="add_audiences_btn" @click="audiance_dialog = true">
                  <span>Add Audience</span>
                </button>
                <v-dialog v-model="audiance_dialog" max-width="50%" persistent>
                  <v-card class="pa-5 radius-10">
                    <div class="">
                      <span>Select a type of audience to create</span>
                    </div>
                    <div style="
                        display: flex;
                        flex-wrap: wrap;
                        padding: 0;
                        gap: 10px;
                      ">
                      <button @click="openCreateCustomerListAudianceDialog"
                        class="d-flex align-start justify-start audiance-option-card" type="button">
                        <img class="image" src="@/assets/snapchatImage/customer-list.svg" alt="" />
                        <div class="data d-flex flex-column">
                          <span class="title">Customer List</span>
                          <span class="desc">Match your customer list with snapchatters.</span>
                          <span class="footer">Source: .csv, .txt</span>
                        </div>
                      </button>
                      <button @click="openCreateWebsiteEventAudienceDialog"
                        class="d-flex align-start justify-start audiance-option-card" type="button">
                        <img class="image" src="@/assets/snapchatImage/website-events.svg" alt="" />
                        <div class="data d-flex flex-column">
                          <span class="title">Website Events</span>
                          <span class="desc">Target Snapchatters who engaged with your
                            website</span>
                          <span class="footer">Source: Snap Pixel</span>
                        </div>
                      </button>
                      <button class="d-flex align-start justify-start audiance-option-card" type="button">
                        <img class="image" src="@/assets/snapchatImage/mobile-app-events.svg" alt="" />
                        <div class="data d-flex flex-column">
                          <span class="title">Mobile App Events</span>
                          <span class="desc">Target Snapchatters who engaged with your app</span>
                          <span class="footer">Source: Snap App</span>
                        </div>
                      </button>
                      <button class="d-flex align-start justify-start audiance-option-card" type="button">
                        <img class="image" src="@/assets/snapchatImage/ad-engagement.svg" alt="" />
                        <div class="data d-flex flex-column">
                          <span class="title">Ad Engagement</span>
                          <span class="desc">Target Snapchatters who engaged with your ads</span>
                          <span class="footer">Source: Past Campaign</span>
                        </div>
                      </button>
                      <button class="d-flex align-start justify-start audiance-option-card" type="button">
                        <img class="image" src="@/assets/snapchatImage/profile-engagement.svg" alt="" />
                        <div class="data d-flex flex-column">
                          <span class="title">Profile Engagement</span>
                          <span class="desc">Target Snapchatters who engaged with your
                            Profile</span>
                          <span class="footer">Source: Public Profile</span>
                        </div>
                      </button>
                      <button class="d-flex align-start justify-start audiance-option-card" type="button">
                        <img class="image" src="@/assets/snapchatImage/lookalike-audiance.svg" alt="" />
                        <div class="data d-flex flex-column">
                          <span class="title">Lookalike Audience</span>
                          <span class="desc">Target Snapchatters with similar characteristics to
                            your existing customers</span>
                          <span class="footer">Source: Existing Audience</span>
                        </div>
                      </button>
                    </div>
                    <!-- <v-row justify="space-between ga-2 ">
                      <v-col cols="4" class="mt-3">
                        <button
                          class="d-flex align-start justify-start audiance-option-card"
                          type="button"
                        >
                          <img
                            class="image"
                            src="@/assets/snapchatImage/advanced-select.svg"
                            alt=""
                          />
                          <div class="data d-flex flex-column">
                            <span class="title">Customer List</span>
                            <span class="desc"
                              >Match your customer list with snapchatters.</span
                            >
                            <span class="footer">Source: .csv, .txt</span>
                          </div>
                        </button>
                      </v-col>
                    </v-row> -->
                    <template v-slot:actions>
                      <v-spacer></v-spacer>

                      <v-btn @click="audiance_dialog = false"> Disagree </v-btn>

                      <v-btn @click="audiance_dialog = false"> Agree </v-btn>
                    </template>
                  </v-card>
                </v-dialog>
              </span>
            </div>
          </div>
        </v-card>
        <CreateNewCustomerListAudience ref="createCustomerListAudienceDialog" />
        <CreateNewWebsiteEvents ref="createWebsiteEventAudienceDialog" />
      </div>


      <LocationComponanet />

    </div>
    <div class="" style="height: 500px"></div>
  </div>
</template>

<script>
import InputLabel from "./Label&input/InputLabel.vue";
import SwitchToogle from "./Label&input/SwitchToogle.vue";
import TreeSelect from "./tree-select-componant/TreeSelect.vue";
import "@/assets/snapchatStyle/style.css";
import CurrencyInput from "./Label&input/CurrencyInput.vue";
import ScheduleCalender from "./ScheduleCalender.vue";
import CreateNewCustomerListAudience from "./CreateAudiance/CreateNewCustomerListAudience.vue";
import CreateNewWebsiteEvents from "./CreateAudiance/CreateNewWebsiteEvents.vue";
import LocationComponanet from "./LocationComponanet.vue";
export default {
  components: {
    InputLabel,
    SwitchToogle,
    TreeSelect,
    CurrencyInput,
    ScheduleCalender,
    CreateNewCustomerListAudience,
    CreateNewWebsiteEvents,
    LocationComponanet,
  },
  data() {
    return {
      directTrafficLocation: "ac-conversion-location-WEB",
      appSelected: false,
      campaignName: "United States, All Genders, All Ages",
      labeltext: "Ad Set Name",
      paused: false,
      notAttached: false,
      alertForApp: false,
      paiedType: [
        {
          id: 1,
          title: "Auto-Bid",
          autoPaied: "Recommended",
          description:
            "Makes best effort to spend your budget. Try this strategy if you want to learn what cost per action you can achieve on Snap.",
        },
        {
          id: 2,
          title: "Max Bid",
          description: "Bids conservatively at or below your desired bid.",
        },
      ],
      bidContant: false,
      bidSelectedView: null,
      bidPer15secound: null,
      validateMaxBid: true,
      selectedOptions: [],
      budget: [
        { title: "Daily Budget", value: "daily_budget" },
        { title: "Lifetime Budget", value: "lifetime_budget" },
      ],
      SelectedBudget: "lifetime_budget",
      daily_budget_end_time: false,
      daily_budget_end_date: "",
      daily_budget_end_date_warning: false,
      ad_scheduling_time: "ad-scheduling-all-the-time",
      // placement data
      placement_type: "automatic_placement",
      edit_placement_type: "content",
      between_content: true,
      between_content_user_stories: true,
      between_content_publisher_and_creator_stories: true,
      between_content_spotlight: true,
      within_content: true,
      within_content_publisher_stories: true,
      within_content_creator_stories: true,
      publisherStoriesCategoriesTypes: ["include", "exclude"],
      publisherStoriesSelectedCategoriesTypes: "include",
      publisherStoriesCategories: ["Food", "Music"],
      publisherStoriesSelectedCategories: "",
      creatorStoriesCategoriesTypes: ["include", "exclude"],
      creatorStoriesSelectedCategoriesTypes: "include",
      creatorStoriesCategories: ["Football", "Art"],
      creatorStoriesSelectedCategories: "",
      camera_ar_lens: true,
      moreOptionsBtn: false,
      moreOptions: true,
      brand_safety_filter: "full_inventory",
      brand_safety_filter_active_fully: "active",
      brand_safety_filter_active_limited: "",
      // Demographics
      age_restrictions_required: false,
      show_age_restrictions_required_validate: false,
      age_restrictions_required_start_age: "13",
      age_restrictions_required_start_end_age: "+50",

      age_restrictions_required_start_age_items: [
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
      ],
      age_restrictions_required_start_end_age_items: [
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "+50",
      ],
      demographics_gender: "all",
      selected_language: [{ name: "English", id: "en" }],
      languages: [
        { name: "English", id: "en" },
        { name: "French", id: "fr" },
        { name: "Spanish", id: "es" },
        { name: "German", id: "de" },
        { name: "Portuguese", id: "pt" },
        { name: "Italian", id: "it" },
      ],
      selected_predefined_audiences: [{ name: "English", id: "en" }],
      predefined_audiences: [
        { name: "English", id: "en" },
        { name: "French", id: "fr" },
        { name: "Spanish", id: "es" },
        { name: "German", id: "de" },
        { name: "Portuguese", id: "pt" },
        { name: "Italian", id: "it" },
      ],
      audiance_dialog: false,
    };
  },
  computed: {
    isMaxBidSelected() {
      return this.bidSelectedView === "Max Bid";
    },
    togglevalidate() {
      return this.bidPer15secound;
    },
    selectedLanguagesHint() {
      return this.selected_language
        .map((language) => `${language.name} (${language.id})`)
        .join(", ");
    },
  },
  methods: {
    toogleTextPaused() {
      this.paused = true;
    },
    toggleAttached() {
      this.notAttached = true;
    },
    alertCard() {
      console.log("ads");
      this.alertForApp = !this.alertForApp;
    },
    togglebidContant() {
      this.bidContant = !this.bidContant;
    },
    moreOptionFun() {
      this.moreOptionsBtn = !this.moreOptionsBtn;
      if (
        this.$refs.moreOptionsContenant.classList.contains("hide-more-option")
      ) {
        this.$refs.moreOptionsContenant.classList.add("d-none");
      } else if (
        !this.$refs.moreOptionsContenant.classList.contains("hide-more-option")
      ) {
        this.$refs.moreOptionsContenant.classList.toggle("d-none");
      }
    },
    removeLangFromList(index) {
      this.selected_language.splice(index, 1);
    },
    openCreateCustomerListAudianceDialog() {
      this.audiance_dialog = false;
      this.$refs.createCustomerListAudienceDialog.openDialog();
    },
  },
  watch: {

    bidPer15secound(value) {
      if (value !== 0) {
        this.validateMaxBid = false;
      } else if (value === "") {
        this.validateMaxBid = true;
      } else {
        this.validateMaxBid = true;
      }
    },


    directTrafficLocation() {
      if (this.directTrafficLocation == "ac-conversion-location-APP") {
        this.appSelected = true;
      } else {
        this.appSelected = false;
      }
    },
    SelectedBudget() {
      console.log(this.SelectedBudget);
    },
    daily_budget_end_date(newDate) {
      if (newDate) {
        const currentDate = new Date();
        const endDate = new Date(newDate);
        const diffTime = endDate - currentDate;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);

        this.daily_budget_end_date_warning = diffDays <= 1;
      } else {
        this.daily_budget_end_date_warning = false;
      }
    },
    brand_safety_filter() {
      if (this.brand_safety_filter == "full_inventory") {
        this.brand_safety_filter_active_fully = "active";
        this.brand_safety_filter_active_limited = "";
      } else {
        this.brand_safety_filter_active_fully = "";
        this.brand_safety_filter_active_limited = "active";


      }
    },
    age_restrictions_required() {
      if (this.age_restrictions_required) {
        if (Number(this.age_restrictions_required_start_age) < 18) {
          this.show_age_restrictions_required_validate = true;
        } else {
          this.show_age_restrictions_required_validate = false;
        }
      }
    },
    age_restrictions_required_start_age() {
      if (
        Number(this.age_restrictions_required_start_age) >
        Number(this.age_restrictions_required_start_end_age)
      ) {
        console.log(this.age_restrictions_required_start_age);
        this.age_restrictions_required_start_end_age =
          this.age_restrictions_required_start_age;
      }
      if (this.age_restrictions_required) {
        if (Number(this.age_restrictions_required_start_age) < 18) {
          this.show_age_restrictions_required_validate = true;
        } else {
          this.show_age_restrictions_required_validate = false;
        }
      }
    },
    age_restrictions_required_start_end_age() {
      if (
        Number(this.age_restrictions_required_start_age) >
        Number(this.age_restrictions_required_start_end_age)
      ) {
        console.log(this.age_restrictions_required_start_end_age);
        this.age_restrictions_required_start_end_age =
          this.age_restrictions_required_start_age;
      }
      if (this.age_restrictions_required) {
        if (Number(this.age_restrictions_required_start_age) < 18) {
          this.show_age_restrictions_required_validate = true;
        } else {
          this.show_age_restrictions_required_validate = false;
        }
      }
    },
    selected_language() {
      console.log(this.selected_language);
    },
  },
};
</script>
