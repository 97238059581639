<template>
  <div>
    <h2>Third</h2>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe earum
      delectus reprehenderit sed excepturi blanditiis quis optio ab dicta
      officia temporibus, inventore nobis recusandae laboriosam quisquam nulla
      maiores dolores eum!
    </p>
  </div>
</template>

<script></script>
