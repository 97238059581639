import { createRouter, createWebHistory } from "vue-router";
// import UserPage from "@/views/porofile/UserPage";
// import UploadImage from "@/views/media/UploadImage";
import TakeTime from "@/views/calender/TakeTime";

// the project content
import SignupView from "@/views/SignupView";
import ErrorPage from "@/views/ErrorPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import HomePage from "@/views/HomePage.vue";
import CreateCampaign from "@/views/CreatCampain.vue";

import SnapChat from "@/views/campains/snapchat/SnapChat.vue";
// Awareness Route
import AwarenessEngagement from "@/views/campains/snapchat/AwarenessEngagement/HomePage.vue";
import FirstStep from "@/views/campains/snapchat/AwarenessEngagement/steps/FirstStep.vue";
import SecoundStep from "@/views/campains/snapchat/AwarenessEngagement/steps/SecoundStep.vue";
import ThirdStep from "@/views/campains/snapchat/AwarenessEngagement/steps/ThirdStep.vue";
// Traffic Route
import Traffic from "@/views/campains/snapchat/Traffic/HomePage.vue";
import TrafficFirstStep from "@/views/campains/snapchat/Traffic/steps/FirstStep.vue";
import TrafficSecoundStep from "@/views/campains/snapchat/Traffic/steps/SecoundStep.vue";
import TrafficThirdStep from "@/views/campains/snapchat/Traffic/steps/ThirdStep.vue";
// Leads Route
import Leads from "@/views/campains/snapchat/leads/HomePage.vue";
import LeadsFirstStep from "@/views/campains/snapchat/leads/steps/FirstStep.vue";
import LeadsSecoundStep from "@/views/campains/snapchat/leads/steps/SecoundStep.vue";
import LeadsThirdStep from "@/views/campains/snapchat/leads/steps/ThirdStep.vue";

//App Promotion
import AppPromotion from "@/views/campains/snapchat/AppPromotion/HomePage.vue";
import AppPromotionFirstStep from "@/views/campains/snapchat/AppPromotion/steps/FirstStep.vue";
import AppPromotionSecoundStep from "@/views/campains/snapchat/AppPromotion/steps/SecoundStep.vue";
import AppPromotionThirdStep from "@/views/campains/snapchat/AppPromotion/steps/ThirdStep.vue";

// Sales
import Sales from "@/views/campains/snapchat/leads/HomePage.vue";
import salesFirstStep from "@/views/campains/snapchat/Sales/steps/FirstStep.vue";
import salesSecoundStep from "@/views/campains/snapchat/Sales/steps/SecoundStep.vue";
import salesThirdStep from "@/views/campains/snapchat/Sales/steps/ThirdStep.vue"

const routes = [
  {
    path: "/",
    name: "SignUp",
    component: SignupView,
    meta: {
      title: "Sign up",
      description: "",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: ErrorPage,
    meta: {
      title: "Not Found",
      description: "",
    },
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
    meta: {
      title: "login",
      description: "",
    },
  },
  {
    path: "/home-page",
    name: "HomePage",
    component: HomePage,
    meta: {
      title: "HomePage",
      description: "",
    },
  },
  {
    path: "/create-new-camp",
    name: "CreateNewCamp",
    component:CreateCampaign,
    meta: {
      title: "Create New Camp",
      description: "",
    }
  },
  {
    path: "/snapchat",
    name: "SnapChat",
    component: SnapChat,
    meta: {
      title: "Snapchat",
      description: "",
    },
  },

  {
    path: "/snapchat/awareness-engagement",
    name: "awareness-engagement",
    component: AwarenessEngagement,
    meta: {
      title: "Snapchat Ads Manager",
      description: "",
    },
    children: [
      {
        path: "/snapchat/awareness-engagement/create-campaign",
        component: FirstStep,
        name: "SnapFirstStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
        },
      },
      {
        path: "/snapchat/awareness-engagement/ad-set-details",

        component: SecoundStep,
        name: "SnapSecoundStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
        },
      },
      {
        path: "/snapchat/awareness-engagement/third-step",
        component: ThirdStep,

        name: "SnapThirdStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
        },
      },
    ],
  },
  {
    path: "/snapchat/traffic",
    name: "traffic",
    component: Traffic,
    meta: {
      title: "Snapchat Ads Manager",
      description: "",
    },
    children: [
      {
        path: "/snapchat/traffic/create-campaign",
        component: TrafficFirstStep,
        name: "TrafficFirstStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
        },
      },
      {
        path: "/snapchat/traffic/secound-step",
        component: TrafficSecoundStep,
        name: "TrafficSecoundStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
        },
      },
      {
        path: "/snapchat/traffic/third-step",
        component: TrafficThirdStep,
        name: "TrafficThirdStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
        },
      },
    ],
  },
  {
    path: "/snapchat/leads",
    name: "Leads",
    component: Leads,
    meta: {
      title: "Snapchat Ads Manager",
      description: "",
    },
    children: [
      {
        path: "/snapchat/leads/create-campaign",
        component: LeadsFirstStep,
        name: "LeadsFirstStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
        },
      },
      {
        path: "/snapchat/leads/secound-step",
        component: LeadsSecoundStep,
        name: "LeadsSecoundStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
        },
      },
      {
        path: "/snapchat/leads/third-step",
        component: LeadsThirdStep,
        name: "LeadsThirdStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
        },
      },
    ],
  },
  {
    path: "/snapchat/app-promotion",
    name: "AppPromotion",
    component: AppPromotion,
    meta: {
      title: "Snapchat Ads Manager",
      description:''
    },
    children: [
      {
        path: "/snapchat/app-promotion/create-campaign",
        name: "appPromotionFirstStep",
        component: AppPromotionFirstStep,
      },
      {
        path: "/snapchat/app-promotion/secound-step",
        name: "appPromotionSecoundStep",
        component: AppPromotionSecoundStep,
      },
      {
        path: "/snapchat/app-promotion/third-step",
        name: "appPromotionThirdStep",
        component: AppPromotionThirdStep,
      },
    ],
  },
  {
    path: "/snapchat/sales",
    name: "Sales",
    component: Sales,
    meta: {
      title: "Snapchat Ads Manager",
      description:''
    },
    children: [
      {
        path: "/snapchat/sales/create-campaign",
        name: "salesFirstStep",
        component: salesFirstStep,
      },
      {
        path: "/snapchat/sales/secound-step",
        name: "salesSecoundStep",
        component: salesSecoundStep,
      },
      {
        path: "/snapchat/sales/third-step",
        name: "salesThirdStep",
        component: salesThirdStep,
      },
    ],
  },

  // {
  //   path: "/media/upload",
  //   name: "UploadImage",
  //   component: UploadImage,
  //   meta: {
  //     title: "Upload Media",
  //   },
  // },
  {
    path: "/calender/bookDate",
    name: "TakeTime",
    component: TakeTime,
    meta: {
      title: "Book Date",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation Guards to protect routes
// router.beforeEach((to, from, next) => {
//   const loggedIn = localStorage.getItem("user");
//   if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
//     next("/login");
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  console.log(from);
  document.title = to.meta.title;
  next();
});

export default router;
