<template>
  <v-container class="pa-0" style="height: 100vh">
    <v-row class="h-100">
      <v-col class="facke-cols" cols="0" md="2" xl="2" sm="0"></v-col>
      <v-col class="contant-form pb-0" md="12" xl="8" sm="8">
        <v-row class="h-100 pa-0" style="background-color: #ebf9f9">
          <v-col sm="12" md="6" xl="6" class="left-side-img">
            <div class="top-logo">
              <img src="@/assets/soft-ware-img/Clip path group.png" alt="" />
            </div>
            <div class="main-image">
              <img
                class=""
                style="width: 100%"
                src="@/assets/soft-ware-img/man-is-holding-phone-sign-that-says 1.png"
                alt=""
              />
            </div>
          </v-col>
          <v-col
            sm="12"
            md="6"
            xl="6"
            class="pa-8"
            style="background-color: #fff; border-radius: 50px 0 0 50px"
          >
            <div class="welcome-page">
              <div class="first-head">
                <h4 class="mb-8">Welcome back</h4>
                <p class="text-center">login to access your account</p>
              </div>
              <form action="" @submit.prevent="login" class="mt-8 mb-3 d-block">
                <div class="first-step">
                  <div class="your-name mb-5">
                    <label class="d-block" for="userName"> Email </label>
                    <input
                      type="email"
                      v-model="userInfo.email"
                      name=""
                      id="userName"
                      :class="emailPlaceholder ? 'error' : 'success'"
                      :placeholder="emailPlaceholder"
                    />
                  </div>
                  <div class="your-name mb-5">
                    <div class="d-flex justify-space-between">
                      <label class="d-block" for="userPhone"> Password </label>
                      <p
                        @click="hideAndShow"
                        class="hide-and-show-icon"
                        style="cursor: pointer"
                      >
                        <span
                          v-if="inputType === 'password'"
                          style="color: #666666cc"
                        >
                          Show
                          <svg
                            width="18"
                            height="18"
                            fill="#666666CC"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path
                              d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"
                            />
                          </svg>
                        </span>
                        <span style="color: #666666cc" v-else>
                          Hide
                          <svg
                            width="18"
                            height="18"
                            fill="#666666CC"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                          >
                            <path
                              d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                            />
                          </svg>
                        </span>
                      </p>
                    </div>
                    <input
                      :type="inputType"
                      :placeholder="passwordPlaceholder"
                      :class="passwordPlaceholder ? 'error' : 'success'"
                      v-model="userInfo.password"
                      name=""
                      id="userPhone"
                    />
                  </div>
                  <LoginBtn
                    @click="toSecoundStemp"
                    :textBtn="textBtn"
                    :type="type"
                  />
                  <div class="have-account my-3">
                    <p>
                      Dont't have an account?
                      <router-link to="/"> sign up </router-link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="facke-cols" cols="0" md="2" xl="2" sm="0"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginBtn from "@/components/buttons/LoginBtn.vue";
import { loginForm } from "@/store/logIn";
import { mapState, mapActions } from "pinia";

export default {
  components: {
    LoginBtn,
  },
  name: "LoginPage",
  data() {
    return {
      textBtn: "Login",
      type: "submit",
      inputType: "password",
    };
  },
  computed: {
    ...mapState(loginForm, ["userInfo", "validateText"]),
    newValidateText() {
      return this.validateText.length ? this.validateText : null;
    },
    passwordPlaceholder() {
      if (
        this.newValidateText &&
        this.newValidateText.includes("Password is required")
      ) {
        return "Password is required";
      }
      return "";
    },
    emailPlaceholder() {
      if (
        this.newValidateText &&
        this.newValidateText.includes("Email is required")
      ) {
        return "Email is required";
      }
      return "";
    },
  },
  methods: {
    ...mapActions(loginForm, ["login"]),
    hideAndShow() {
      this.inputType = this.inputType === "text" ? "password" : "text";
    },
  },
};
</script>

<style scoped>
@media (max-width: 1220px) {
  .facke-cols {
    display: none;
  }
  .contant-form {
    margin: 0 auto;
  }
}
.v-selection-control {
  align-items: start !important;
}
.welcome-page {
  border: 1px solid #66666659;
  padding: 15px 30px;
  border-radius: 20px;
  width: 90%;
  margin: 0 auto;
  height: 100%;
  position: relative;
}
.welcome-page h4 {
  color: #000;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}
.welcome-page .first-head p {
  font-size: 24px;
  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}
.welcome-page .request-have-account h3 {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  text-align: center;
}
.welcome-page .request-have-account p {
  color: #666666;
  font-size: 16px;
  text-align: center;
}

.welcome-page form .first-step .your-name label {
  color: #666666;
  font-size: 16px;
}
.welcome-page form .first-step .your-name input {
  border: 1px solid #66666659 !important;
  padding: 13px 10px;
  width: 100%;
  border-radius: 15px;
  outline: none;
}
.welcome-page form .first-step .your-name input:hover {
  outline: none;
}
.contant-form .left-side-img {
  background-color: #ebf9f9;
  border-radius: 40px 0 0 40px;
}
.main-image img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.first-step .have-account p {
  font-size: 16px;
  color: #49475a;
  font-weight: 500;
  text-align: center;
}
.first-step .have-account p a {
  color: #1f666a;
}
.your-name .hide-and-show-icon span {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}
.error {
  border: 1px solid #e84e3c !important;
  outline: 1px solid #e84e3c !important;
}
/* .success {
  border: 1px solid green !important;
  outline: 1px solid green !important;
} */
</style>
