<template>
    <router-view></router-view>
  </template>
  
  <script>
  import "@/assets/snapchatStyle/style.css";
  export default {
    mounted() {
      this.$router.push({ name: "appPromotionFirstStep" });
    },
    methods: {
      goTonextStep() {
        this.$router.push({ name: "appPromotionSecoundStep" });
      },
    },
  };
  </script>
  