<template>
  <router-view></router-view>
</template>

<script>
import "@/assets/snapchatStyle/style.css";
export default {
  mounted() {
    this.$router.push({ name: "TrafficFirstStep" });
  },
  methods: {
    goTonextStep() {
      this.$router.push({ name: "TrafficSecoundStep" });
    },
  },
};
</script>
